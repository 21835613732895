/* COMPONENT 5.7 Page Header Foobys
##################################################################################################################### */

.page-header-foobys {
    background-color: $color-white;
    overflow: hidden;

    @media #{$medium-up} {
        background-color: $color-light-grey;
    }
}

.page-header-foobys__hero-image-wrapper {
    width: 100%;
    padding: 0;
    position: fixed;
    top: rem-calc(50px);
    background-color: $color-white;
    left: 0;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    padding-top: 66.666%;
    //transition-duration: 0ms;
    //transition-property: transform;

    @media #{$medium-up} {
        top: rem-calc(70px);
    }

    @media #{$large-up} {
        //height: 50vh;
        top: rem-calc(70px);
        height: calc(100vh - 70px);
        padding: 0 rem-calc(20px) rem-calc(20px);
    }
}

.page-header-foobys__hero-image-wrapper-spacer {
    width: 100%;
    padding: 0;
    //position: fixed;
    //top: rem-calc(50px);
    //background-color: $color-white;
    left: 0;
    //transform: translate3d(0, 0, 0);
    //backface-visibility: hidden;
    padding-top: 66.666%;

    @media #{$large-up} {
        display: none;
    }
}

@media #{$xlarge-up} {
    body:not(.touch) {
        .page-header-foobys__hero-image-wrapper {
            top: rem-calc(100px);
            height: calc(100vh - 100px);
        }
    }
}

.page-header-foobys__hero-image {
    background: transparent no-repeat center center / cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &::after {
        content: " ";
        position: absolute;
        border: 2px solid $color-white;
        top: rem-calc(7px);
        right: rem-calc(7px);
        bottom: rem-calc(7px);
        left: rem-calc(7px);
    }

    @media #{$medium-up} {
        &::after {
            top: rem-calc(20px);
            right: rem-calc(20px);
            bottom: rem-calc(20px);
            left: rem-calc(20px);
        }
    }

    @media #{$large-up} {
        top: 0;
        right: rem-calc(20px);
        bottom: rem-calc(20px);
        left: rem-calc(20px);

        &::after {
            top: rem-calc(20px);
            right: rem-calc(20px);
            bottom: rem-calc(20px);
            left: rem-calc(20px);
        }
    }
}

.page-header-foobys__hero-image-image {
    @extend %header-image;
}

.page-header-foobys__panel-wrapper {
    position: relative;
    //margin-top: calc(50vh - 20px);
    margin-top: rem-calc(-17px);

    @media #{$medium-up} {
        //margin-top: calc(50vh - 60px);
        margin-top: rem-calc(-63px);
    }

    @media #{$large-up} {
        margin-top: calc(100vh - 70px - 105px);
    }
}

@media #{$xlarge-up} {
    body:not(.touch) {
        .page-header-foobys__panel-wrapper {
            margin-top: calc(100vh - 100px - 105px);
        }
    }
}

.page-header-foobys__panel {
    background-color: $color-white;
    position: relative;
    padding: rem-calc(26px) 0 rem-calc(10px);

    .floating-title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }

    @media #{$medium-up} {
        //padding-top: rem-calc(41px);
        padding-top: rem-calc(40px);
        padding-bottom: rem-calc(30px);
    }

    @media #{$xlarge-up} {
        //padding-top: rem-calc(45px);
        padding-top: rem-calc(30px);
        padding-bottom: rem-calc(30px);
    }
}

.page-header-foobys__panel-title {
    text-align: center;

    @media #{$medium-up} {
        text-align: left;
    }
}

// .page-header-foobys__panel-title-small {
//     text-align: center;
//     padding-top: rem-calc(10px);
//     margin-bottom: rem-calc(17px);
// }

.page-header-foobys__panel-sub-title,
.page-header-foobys__panel-copy,
.page-header-foobys__avatar-copy p {
    padding-left: rem-calc(15px);
    padding-right: rem-calc(15px);

    @media #{$medium-up} {
        padding-left: 0;
        padding-right: 0;
    }
}

.page-header-foobys__panel-copy > p {
    @extend %paragraph--copy-intro;
}


.page-header-foobys__panel-title-wrapper {

    @media #{$medium-only} {
        min-height: rem-calc(150px);
    }

    @media #{$medium-up} {
        padding-left: rem-calc(210px);
        padding-right: rem-calc(100px);

        .paragraph--subtitle {
            text-align: left;
        }
    }

    @media #{$large-up} {
        padding-left: rem-calc(310px);
        padding-right: rem-calc(100px);
    }
}

.page-header-foobys__panel-avatar-wrapper {
    width: 100%;
    max-width: rem-calc(250px);
    margin: 0 auto rem-calc(30px);
    padding-left: rem-calc(15px);
    padding-right: rem-calc(15px);

    @media #{$medium-up} {
        position: absolute;
        top: 0;
        max-width: 150px;
        padding: 0;
        left: 45px;
        margin-bottom: 0;
    }

    @media #{$large-up} {
        max-width: 250px;
    }
}

.page-header-foobys__avatar {
    max-width: 100%;
    max-height: rem-calc(250px);
}

.page-header-foobys__panel-text-wrapper {
    @media #{$medium-up} {
        padding-left: rem-calc(30px);
        padding-right: rem-calc(30px);
    }

    @media #{$large-up} {
        padding-left: rem-calc(310px);
        padding-right: rem-calc(100px);
    }
}

.page-header-foobys__weblink-wrapper {
    text-align: center;
    margin-top: rem-calc(20px);

    @media #{$large-up} {
        text-align: left;
    }
}

.page-header-foobys__weblink {
    font-size: rem-calc(11px);
    line-height: (14 / 11);
    font-family: $font-family-montserrat;
    font-weight: bold;
    color: $color-stone-grey;
    text-transform: uppercase;
    letter-spacing: calc-em(1px);
    transition: color $animation-time-default $animation-easing-default;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    &:hover,
    &:active,
    &:focus {
        color: $color-dark-stone-grey;
    }
}

// Spacer bottom

.page-header-foobys__grey-space {
    height: rem-calc(20px);
    background-color: $color-light-grey;

    @media #{$medium-up} {
        height: rem-calc(30px);
    }
}

/* Modifiers
##################################################################################################################### */

.page-header-foobys--community {
    .page-header-foobys__avatar {
        border-radius: 51%;
    }
}

.page-header-foobys--partner {
    // no special styles
}
