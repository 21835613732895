/* BOOTSTRAP OVERWRITES
##################################################################################################################### */

.container {
    padding-left: rem-calc($container-padding-small);
    padding-right: rem-calc($container-padding-small);
    max-width: rem-calc($max-container-width);

    & + .container:not(.container--force-top-padding) {
        padding-top: 0;
    }

    @media #{$medium-up} {
        padding-left: rem-calc($container-padding-medium);
        padding-right: rem-calc($container-padding-medium);
    }

    @media #{$large-up} {
        padding-left: rem-calc($container-padding-large);
        padding-right: rem-calc($container-padding-large);
    }
}

.container--horizontal-padding-small {
    padding-top: rem-calc(30px);
    padding-bottom: rem-calc(18px); // the additional 12px come from the horizontal gutter of the cell

    @media #{$medium-up} {
        padding-bottom: 0; // the additional 30px come from the horizontal gutter of the cell
    }
}

.container--horizontal-padding-medium {
    padding-top: rem-calc(60px);
    padding-bottom: rem-calc(48px); // the additional 12px come from the horizontal gutter of the cell

    @media #{$medium-up} {
        padding-bottom: rem-calc(30px); // the additional 30px come from the horizontal gutter of the cell
    }
}

.container--horizontal-padding-large {
    padding-top: rem-calc(90px);
    padding-bottom: rem-calc(78px); // the additional 12px come from the horizontal gutter of the cell

    @media #{$medium-up} {
        padding-bottom: rem-calc(60px); // the additional 30px come from the horizontal gutter of the cell
    }
}

.container--no-padding-top {
    padding-top: 0;
}

.container--fluid {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    width: 100%;
    overflow: hidden;

    @media #{$medium-up} {
        padding-left: 0;
        padding-right: 0;
    }

    @media #{$large-up} {
        padding-left: 0;
        padding-right: 0;
    }
}

.row {
    margin-left: rem-calc($gutter-small *- 1 / 2);
    margin-right: rem-calc($gutter-small *- 1 / 2);

    @media #{$medium-up} {
        margin-left: rem-calc($gutter-medium *- 1 / 2);
        margin-right: rem-calc($gutter-medium *- 1 / 2);
    }

    &--half-gutter-md {
        @media #{$medium-up} {
            margin-left: rem-calc($gutter-medium *- 1 / 4);
            margin-right: rem-calc($gutter-medium *- 1 / 4);
        }

        [class^="col-"],
        [class*=" col-"] {
            padding-left: rem-calc($gutter-medium / 4);
            padding-right: rem-calc($gutter-medium / 4);
        }
    }

    &--no-gutter {
        margin-left: 0;
        margin-right: 0;

        [class^="col-"],
        [class*=" col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--fullwidth {
        width: 100%;
    }

    &--flex-center {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        [class^="col-"],
        [class*=" col-"] {
            display: flex;
            float: none;
        }
    }
}

[class^="col-"],
[class*=" col-"] {
    padding-left: rem-calc($gutter-small / 2);
    padding-right: rem-calc($gutter-small / 2);

    @media #{$medium-up} {
        padding-left: rem-calc($gutter-medium / 2);
        padding-right: rem-calc($gutter-medium / 2);
    }
}

@media #{$small-only} {
    [class*="block-grid-"] > * {
        padding-left: rem-calc($gutter-small / 2);
        padding-right: rem-calc($gutter-small / 2);
    }
}

@media #{$large-up} {
    .col-md-1-5 {
        width: 20%;
    }

    .col-md-2-5 {
        width: 40%;
    }
}

@media #{$small-only} {
    .block-grid-xs-1 {
        padding-left: rem-calc(6px);
        padding-right: rem-calc(6px);
        margin-left: rem-calc(-6px);
        margin-right: rem-calc(-6px);
    }
}
