/* COMPONENT Logo
##################################################################################################################### */

.header-logo {
    transition: all $animation-time-default $animation-easing-default;
    transform: translateZ(0);
}

.header-logo__link {
    display: block;
    position: relative;

    @if ($little-fooby == false) {
        &::after {
            content: " ";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: $color-white;
            height: 17.36%;
            z-index: 11;
            opacity: 0;
            transition: opacity $animation-time-default $animation-easing-default;

            @media #{$xlarge-up} {
                bottom: -1px;
                height: 18.36%;
            }
        }
    }
}

.header-logo__logo {
    display: block;
    width: 100%;
}
