@if ($little-fooby) {
    .title-lines {
        position: absolute;
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: $font-family-northernsoul-caps;

        &__line {
            position: relative;
            display: inline-flex;
            white-space: nowrap;

            &::before {
                z-index: -1;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }
}