.readmore {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__btn {
        appearance: none;
        border: none;
        background: none;
        margin-top: rem-calc(8px);
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: rem-calc(12px);
        line-height: 2;
        font-weight: bold;
        color: $color-stone-grey;
    }

    &__btn-linkless {
        display: none;
    }

    &__icon {
        display: inline-flex;
        color: $color-dark-grey;
    }

    /* Expandable */

    &.is-expandable &__copy {
        position: relative;
        overflow: hidden;

        &::after {
            pointer-events: none;
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 100%;
            height: rem-calc(28px);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        }
    }

    &.is-expandable &__copy p {
        margin: 0;
    }

    &:not(.is-expandable) &__btn {
        display: none;
    }

    /* Open variant */

    &.is-open &__btn-link {
        display: none;
    }

    &.is-open &__btn-linkless {
        display: inline;
    }

    &.is-open &__icon {
        transform: rotate(180deg);
    }

    &.is-open &__copy {
        max-height: none;

        &::after {
            display: none;
        }
    }
}
