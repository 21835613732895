/* COMPONENT Page Header Suche
##################################################################################################################### */

.page-header-search {
    width: 100%;
    height: calc(100vh - 50px);
    position: relative;
    display: flex;
    flex-direction: column;

    @media #{$small-only} {
        margin-bottom: rem-calc(20px); // compensate missing bottom spce from image on mobile
    }

    @media #{$medium-up} {
        min-height: rem-calc(400px);
        height: calc(90vh - 70px);
    }

    @media #{$large-up} {
        min-height: rem-calc(450px);
    }
}

@media #{$xlarge-up} {
    body:not(.touch) {
        .page-header-search {
            height: calc(90vh - 100px);
        }
    }
}

.page-header-search__popular {
    position: relative;
    padding-top: rem-calc(16px);
    background-color: $color-light-grey;

    @media #{$medium-up} {
        display: none;
    }

    &::after,
    &::before {
        z-index: 1;
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 34px;
        height: 100%;
        flex-shrink: 0;
        background: linear-gradient(270deg, #F0F0F0 18.57%, rgba(240, 240, 240, 0) 100%);
        opacity: 0;
        transition: opacity .2s ease-in;
    }

    &::after {
        right: 0;
    }

    &::before {
        left: 0;
        transform: rotate(180deg);
    }

    &.has-left-scroll::before {
        opacity: 1;
    }

    &.has-right-scroll::after {
        opacity: 1;
    }
}

.page-header-search__popular-title {
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    line-height: (14/10);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $color-stone-grey;
}

.page-header-search__popular-list-wrapper {
    display: flex;
    overflow: scroll;
    overflow-y: hidden;
    padding-top: rem-calc(8px);
    padding-bottom: rem-calc(16px);
}

.page-header-search__popular-list-inner-wrapper {
    display: flex;
    // 78px + 2px from vertical borders
    // used to prevent layout shifting when popular recipes have not been loaded yet
    min-height: rem-calc(80px);
}

.page-header-search__popular-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
}

.page-header-search__popular-list-item {
    margin: 0 rem-calc(4px);

    &:first-child {
        margin-left: rem-calc(16px);
    }

    &:last-child {
        margin-right: rem-calc(16px);
    }
}

.page-header-search__hero-image {
    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    flex-grow: 1;
    background: no-repeat center center;
    background-size: cover;
    overflow: hidden;
    padding: 0;

    @media #{$medium-up} {
        margin-bottom: rem-calc(20px);
        // bottom: rem-calc(20px);
    }

    @media #{$large-up} {
        margin-right: rem-calc(20px);
        margin-left: rem-calc(20px);
        // right: rem-calc(20px);
        // left: rem-calc(20px);
    }
}

.page-header-search__hero-image-image {
    @extend %header-image;
    object-position: center center;
}

.page-header-search__scrollhint {
    position: absolute;
    bottom: 23%;
    left: 50%;
    text-align: center;
    width: 100%;
    max-width: rem-calc(320px);
    transform: translate(-50%, 50%);
    cursor: pointer;
    z-index: 2;

    @media screen and (max-height: 510px) {
        display: none;
    }
}

.page-header-search__scrollhint-text {
    font-size: rem-calc(11px);
    font-family: $font-family-montserrat;
    font-weight: bold;
    text-transform: uppercase;
    line-height: rem-calc(14px);
    display: block;
    text-align: center;
    color: $color-white;
    margin-bottom: rem-calc(20px);

    @media #{$medium-up} {
        margin-bottom: rem-calc(30px);
    }
}

@keyframes scrollhint-animation {
    0% {
        transform: translate3d(0, -6px, 0);
    }

    50% {
        transform: translate3d(0, 6px, 0);
    }

    100% {
        transform: translate3d(0, -6px, 0);
    }
}

.page-header-search__scrollhint-icon {
    transform: translate3d(0, -6px, 0);
    animation: scrollhint-animation 1.6s infinite $animation-easing-default;
}

@media screen and (min-width: 992px) and (max-height: 800px) {
    .page-header-search__scrollhint {
        display: none;
    }
}
