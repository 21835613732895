/* COMPONENT Tag Cloud
##################################################################################################################### */

.tag-cloud {
    // empty rule
}

.tag-cloud__inner {
    padding-top: rem-calc(10px);
    overflow: hidden;
    margin-bottom: rem-calc(-15px); // fixing the marginbottom from the tags, would result in too big space at bottom

    .tag {
        display: block;
        float: left;

        @if ($little-fooby) {
            margin-right: rem-calc(8px);
            margin-bottom: rem-calc(12px);
        } @else {
            margin-right: rem-calc(15px);
            margin-bottom: rem-calc(15px);
        }
    }
}
