/* COMPONENT Navigation
##################################################################################################################### */

// sass-lint:disable-all

$inset-left: 12px;

%lvl-one-link-paddings {
    padding-left: #{rem-calc($inset-left)};
    padding-right: #{rem-calc($inset-left)};
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.navigation-desktop {
    display: none;
    position: fixed;
    z-index: 90;
    opacity: 0;

    right: rem-calc(16px);
    left: rem-calc(16px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @if ($little-fooby) {
        background-color: $panel-background-color;
    } @else {
        background-color: $color-light-grey;
    }

    // spacing from top and bottom on mobile
    top: rem-calc(50px);
    bottom: rem-calc(96px);

    @media #{$medium-up} {
        right: rem-calc(38px);
        left: rem-calc(38px);

        // spacing from top and bottom on tablet or higher
        top: rem-calc(90px);
        bottom: rem-calc(131px);
    }

    @media #{$large-up} {
        right: rem-calc(60px);
        left: rem-calc(60px);
    }
}

.navigation-desktop__l1-list {
    padding-top: rem-calc(40px);
    padding-bottom: rem-calc(30px);
    width: 100%;

    @media #{$medium-up} {
        padding-top: rem-calc(90px);
        padding-bottom: rem-calc(80px);
    }
}

.navigation-desktop__l1-list,
.navigation-desktop__l2-list {
    @extend %unstyled-list;
}

/* Level 1 */

.navigation-desktop__dropdown-icon-wrapper {
    display: none;
}

.navigation-desktop__l1-list-item,
.navigation-desktop__l2-list-item {
    display: block;
}

.navigation-desktop__l1-list-item {
    margin-bottom: rem-calc(10px);
    overflow: hidden;
}

.navigation-desktop__l1-link {
    @extend %lvl-one-link-paddings;
    display: block;
    font-size: rem-calc(15px);
    font-weight: 700;
    font-family: $font-family-montserrat;
    text-transform: uppercase;
    line-height: rem-calc(40px);
    text-align: center;
    color: $color-dark-grey;
    text-decoration: none;
    outline: none;
    display: flex;
    justify-content: center;

    &:active,
    &:hover,
    &:focus,
    &--state-active {
        color: $theme-color;
    }

    @media #{$medium-up} {
        line-height: rem-calc(64px);
        font-size: rem-calc(20px);
    }
}


/* Level 2 */

.navigation-desktop__l2-list {
    width: 100%;
}

.navigation-desktop__l2-link {
    @extend %lvl-one-link-paddings;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: rem-calc(40px);
    font-size: rem-calc(15px);
    font-family: $font-family-open-sans;
    font-weight: 400;
    text-align: center;
    width: 100%;
    color: $color-dark-stone-grey;
    text-decoration: none;

    &:hover {
        color: $color-dark-stone-grey;
    }

    @media #{$medium-up} {
        line-height: rem-calc(64px);
        font-size: rem-calc(20px);
        white-space: nowrap;
    }

    @media #{$xlarge-up} {
        justify-content: flex-start;
    }

    .svg-group {
        margin-right: rem-calc(9px);
    }

    .svg-group--stars .filled .animated {
        fill: currentColor;
    }

    .svg-group--stars .animated-stroke {
        stroke: currentColor;
    }

    &--lf-l1:hover {
        color: $theme-color;
    }

    &--lf-l2:hover {
        color: $theme-color-l2;
    }

    &--lf-l3:hover {
        color: $theme-color-l3;
    }
}

.navigation-desktop__l2-link:active,
.navigation-desktop__l2-link:focus,
.navigation-desktop__l2-link--state-active {
    text-decoration: none;
    color: $theme-color;
    outline: none;
}

/* Metanavigation */

.navigation-meta {
    position: relative;
    right: rem-calc(-10px);
    top: 50%;
    transform: translateY(-50%);
    float: right;
}

.navigation-meta__l1-list {
    @extend %unstyled-list;
    float: right;
}

.navigation-meta__l1-list-item {
    float: left;
}

.navigation-meta__l1-link {
    padding-left: rem-calc(5px);
    padding-right: rem-calc(10px);
    font-size: rem-calc(12px);
    font-family: $font-family-open-sans;
    line-height: rem-calc(50px);
    display: block;
    color: $color-dark-grey;
    text-decoration: none;
    transition: color $animation-time-default $animation-easing-default;
    display: flex;
    align-items: center;

    .my-fooby-svg,
    .search-svg,
    .magazin-svg {
        position: relative;
        top: -1px;
        vertical-align: middle;
    }

    // Dirty, but was neccessary, that the markup doesn't need to be changed
    // sass-lint:disable-all
    .animated,
    .my-fooby-svg > g > g {
        transition: fill $animation-time-default $animation-easing-default;
    }
    /* scss-lint:enable all */

    @media only screen and (min-width: 400px) {
        padding-left: rem-calc(10px);
    }
}

.navigation-meta__l1-link:hover,
.navigation-meta__l1-link:active,
.navigation-meta__l1-link:focus,
.navigation-meta__l1-link--state-active {
    color: $theme-color;
    text-decoration: none;

    // Dirty, but was neccessary, that the markup doesn't need to be changed
    // sass-lint:disable-all
    .animated,
    .my-fooby-svg > g > g {
        fill: $theme-color;
    }
    /* scss-lint:enable all */
}

.navigation-meta__fooby-link {
    display: none;

    @media #{$medium-up} {
        display: block;
    }

    .navigation-meta__link-inner {
        display: block;
        padding: 0 rem-calc(20px);
        background-color: rgba($color-green, 0.2);
        color: $color-green;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.2px;
        font-family: $font-family-montserrat;
        line-height: rem-calc(30px);
        margin-top: rem-calc(10px);
        font-size: rem-calc(12px);
    }
}

.navigation-meta__touch-coop-link {
    position: absolute;
    font-size: rem-calc(15px);
    line-height: rem-calc(45px);
    padding: 0 rem-calc(20px);
    z-index: 91;
    font-family: $font-family-open-sans;
    left: 0;
    right: 0;
    color: $color-dark-grey;
    text-decoration: none;
    transition: color $animation-time-default $animation-easing-default;

    // Language nav (50px) + 1px grey line
    bottom: rem-calc(51px);

    // adding the grey line under the link
    &::after {
        background-color: $color-light-grey;
        bottom: -1px;
        content: " ";
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
    }

    @media #{$medium-up} {
        line-height: rem-calc(65px);

        // Language nav (65px) + 1px grey line
        bottom: rem-calc(66px);
    }
}

.navigation-meta__touch-coop-link-span {
    vertical-align: middle;
}

.navigation-meta__touch-coop-link-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: rem-calc(30px);

    svg {
        display: block;
    }

    @media #{$medium-up} {
        margin-right: rem-calc(40px);
    }
}

.navigation-meta__touch-coop-link:hover,
.navigation-meta__touch-coop-link:active,
.navigation-meta__touch-coop-link:focus,
.navigation-meta__touch-coop-link--state-active {
    color: $color-green;
    text-decoration: none;
}

/* Burger */

.navigation-hamburger {
    width: rem-calc(30px);
    height: rem-calc(30px);
    position: relative;
    cursor: pointer;
    top: 50%;
    transform: translateY(-52%);

    * {
        transition: all $animation-time-default $animation-easing-default;
    }

    span {
        display: block;
        background: $color-dark-stone-grey;
        border-radius: 2px;
    }
}

.navigation-hamburger__hamburger {
    position: absolute;
    height: 100%;
    width: 100%;

    span {
        width: rem-calc(26px);
        height: rem-calc(2px);
        position: relative;
        top: 0;
        left: rem-calc(2px);
        margin: rem-calc(7px) 0;

        &:nth-child(1) {
            transition-delay: .5s;
        }

        &:nth-child(2) {
            transition-delay: .625s;
        }

        &:nth-child(3) {
            transition-delay: .75s;
        }
    }
}

.navigation-hamburger__cross {
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(45deg);

    span {
        &:nth-child(1) {
            height: 0;
            width: rem-calc(2px);
            transition-delay: 0s;
            left: 13px;
            position: absolute;
            top: 6px;

        }

        &:nth-child(2) {
            width: 0;
            height: rem-calc(2px);
            transition-delay: .25s;
            left: 1px;
            position: absolute;
            top: 18px;
        }
    }
}

.header--open {
    .navigation-hamburger__hamburger span {
        width: 0;

        &:nth-child(1) {
            transition-delay: 0s;
        }

        &:nth-child(2) {
            transition-delay: .125s;
        }

        &:nth-child(3) {
            transition-delay: .25s;
        }
    }

    .navigation-hamburger__cross span {
        &:nth-child(1) {
            height: 90%;
            transition-delay: .625s;
        }

        &:nth-child(2) {
            width: 90%;
            transition-delay: .375s;
        }
    }
}

// overwrite everything here for desktop view of header
@media #{$xlarge-up} {
    body:not(.touch) {

        .navigation-desktop {
            background-color: transparent;
            height: auto;
            opacity: 1;
            display: block;
            top: 0;
            position: static;
            overflow: visible;
        }

        /* Level 1 */

        .navigation-desktop__l1-list {
            position: relative;
            left: #{rem-calc($inset-left*-1)};
            background-color: transparent;
            padding-top: 0;
            padding-bottom: 0;
            width: auto;
        }

        .navigation-desktop__l1-list-item {
            overflow: visible;
            float: left;
            margin-bottom: 0;

            //&:not(:last-child) {
            //    margin-right: rem-calc(10px);
            //}
        }

        .navigation-desktop__l1-link {
            font-size: rem-calc(14px);
            font-family: $font-family-open-sans;
            line-height: rem-calc(100px);
            text-align: left;
            transition: line-height $animation-time-default $animation-easing-default;
        }

        .navigation-desktop__link {
            transition: color $animation-time-fast $animation-easing-default;
        }

        .navigation-desktop__dropdown-icon-wrapper {
            display: inline-block;
            margin-left: rem-calc(8px);
        }

        /* Level 2 */

        .navigation-desktop__l2-list {
            width: auto;
            position: absolute;
            z-index: 8;
            background-color: $color-white;
            max-height: 0;
            transition: max-height $animation-time-default $animation-easing-default;
            will-change: max-height;
            overflow: hidden;
        }

        .navigation-desktop__l2-link {
            width: auto;
            min-width: rem-calc(140px);
            padding-left: rem-calc(20px);
            padding-right: rem-calc(30px);
            font-size: rem-calc(14px);
            text-align: left;
            line-height: rem-calc(40px);
            transition: background-color $animation-time-default $animation-easing-fast,
                color $animation-time-fast $animation-easing-default;
        }

        .navigation-desktop__l2-list-item:not(:first-child) {
            &::after {
                content: " ";
                position: absolute;
                top: -1px;
                left: rem-calc(20px);
                right: rem-calc(20px);
                height: 1px;
                background-color: $color-light-grey;
                transition: opacity $animation-time-default $animation-easing-fast;
            }
        }

        // Old - now calculated in js
        //.navigation-desktop__l1-list-item:hover {
        //    .navigation-desktop__l2-list {
        //        max-height: 160px;
        //    }
        //}

        .navigation-desktop__l2-list-item {
            position: relative;
        }

        .navigation-desktop__l2-list-item:hover {
            &::after {
                opacity: 0;
            }
        }

        .navigation-desktop__l2-link:hover {
            background-color: $color-light-grey;
        }

        /* Metanavigation */

        .navigation-meta {
            right: rem-calc(-20px);
        }

        .navigation-meta__l1-link {
            padding-left: rem-calc(20px);
            padding-right: rem-calc(20px);
            font-size: rem-calc(14px);

            .my-fooby-svg {
                height: rem-calc(18px);
                width: rem-calc(18px);
            }

            .search-svg {
                height: rem-calc(16px);
                width: rem-calc(16px);
            }
        }

        .navigation-meta__coop-link {
            display: inline-block;
        }

        .navigation-meta__touch-coop-link {
            display: none;
        }

        /* Burger */

        .navigation-hamburger {
            display: none;
        }
    }
}
