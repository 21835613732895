/* COMPONENT author-info
##################################################################################################################### */

.author-info {
    color: inherit;
    transition: color .2s ease-in-out;
    display: block;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: $color-green;
    }

    @media #{$medium-up} {
        display: table;
    }
}

.author-info__profile-img,
.author-info__copy {
    @media #{$medium-up} {
        vertical-align: middle;
        display: table-cell;
    }
}

.author-info__profile-img {
    margin-bottom: rem-calc(14px);
    text-align: center;

    img {
        display: inline-block;
        height: rem-calc(68px);
        width: rem-calc(68px);
        border-radius: 100%;
    }

    @media #{$medium-up} {
        padding-right: rem-calc(20px);
        margin-bottom: 0;
    }
}

.author-info__copy {
    p {
        font-size: rem-calc(14px);
        line-height: rem-calc(18px);
        margin: 0;
        text-align: center;

        @media #{$medium-up} {
            text-align: left;
        }
    }
}
