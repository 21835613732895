/* COMPONENT Share Group
##################################################################################################################### */

.share-group {
    @extend %inline-list;
    text-align: center;
    line-height: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    /* has to be here because of extend */
    .share-group__single {
        line-height: rem-calc(18px);

        &:not(:last-child) {
            margin-right: 35px;
        }

        @media #{$medium-up} {
            line-height: rem-calc(24px);

            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }
}

.share-group__single-link {
    display: block;

    &,
    &:hover,
    &:active,
    &:focus,
    &:not(.share-group__single-link--no-hover) {
        text-decoration: none;
    }
}

[data-like-save] {
    cursor: pointer;
}

[data-like-count]:not(:empty) {
    margin-right: rem-calc(4px);
}

.share-group__single-share-icon {
    text-align: center;
    display: flex;
    justify-content: center;

    svg {
        display: block;
        margin: 0 auto;
    }

    @media #{$large-up} {
        position: relative;
        display: inline-flex;
        margin-right: rem-calc(6px);
        vertical-align: middle;
    }
}

// scoping these margins to full page-action-bar
[data-page-actions] {
    .share-group__single-share-icon {
        margin-bottom: rem-calc(8px);

        @media #{$large-up} {
            margin-bottom: 0;
        }
    }
}

.share-group__single-share-copy {
    display: flex;
    text-align: center;
    font-size: rem-calc(10px);
    font-weight: 700;
    font-family: $font-family-montserrat;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $color-stone-grey;
    transition: color $animation-time-default $animation-easing-default;

    @media #{$medium-up} {
        font-size: rem-calc(12px);
        line-height: rem-calc(14px);
    }

    @media #{$large-up} {
        display: inline-flex;
        align-items: baseline;
    }
}

//.share-group__single-link:active:not(.share-group__single-link--no-hover),
//.share-group__single-link:hover:not(.share-group__single-link--no-hover),
//.share-group__single-link:focus:not(.share-group__single-link--no-hover),
////.like--active,
//.bookmark--active {
//
//    .share-group__single-share-copy {
//        color: $color-green;
//    }
//
//    svg .animated {
//        fill: $color-green;
//    }
//
//    svg .animated-stroke {
//        stroke: $color-green;
//    }
//}

.share-group__single-link:hover:not(.share-group__single-link--no-hover),
.share-group__single-link.tooltip-trigger--active,
.bookmark--active,
.like--active {
    .share-group__single-share-copy {
        color: $theme-color;

        .little-fooby-l2 & {
            color: $theme-color-l2;
        }

        .little-fooby-l3 & {
            color: $theme-color-l3;
        }

        .little-fooby-l1.share-group &,
        .little-fooby-l1.teaser & {
            color: $theme-color-l1;
        }

        .little-fooby-l2.share-group &,
        .little-fooby-l2.teaser & {
            color: $theme-color-l2;
        }

        .little-fooby-l3.share-group &,
        .little-fooby-l3.teaser & {
            color: $theme-color-l3;
        }
    }

    svg,
    svg .animated,
    svg .fill {
        fill: $theme-color;

        .little-fooby-l2 & {
            fill: $theme-color-l2;
        }

        .little-fooby-l3 & {
            fill: $theme-color-l3;
        }

        .little-fooby-l1.share-group &,
        .little-fooby-l1.teaser & {
            fill: $theme-color-l1;
        }

        .little-fooby-l2.share-group &,
        .little-fooby-l2.teaser & {
            fill: $theme-color-l2;
        }

        .little-fooby-l3.share-group &,
        .little-fooby-l3.teaser & {
            fill: $theme-color-l3;
        }
    }

    svg .animated-all {
        fill: $theme-color;
        stroke: $theme-color;

        .little-fooby-l2 & {
            fill: $theme-color-l2;
            stroke: $theme-color-l2;
        }

        .little-fooby-l3 & {
            fill: $theme-color-l3;
            stroke: $theme-color-l3;
        }

        .little-fooby-l1.share-group &,
        .little-fooby-l1.teaser & {
            fill: $theme-color-l1;
            stroke: $theme-color-l1;
        }

        .little-fooby-l2.share-group &,
        .little-fooby-l2.teaser & {
            fill: $theme-color-l2;
            stroke: $theme-color-l2;
        }

        .little-fooby-l3.share-group &,
        .little-fooby-l3.teaser & {
            fill: $theme-color-l3;
            stroke: $theme-color-l3;
        }
    }

    .share-icon {
        // Fill-Animationen now with Greensock for IE11 compatibility
        @include icon-fill-anim(false, .2s);
    }
}

[data-like-save] {
    svg .animated-all {
        fill: $theme-color;

        .little-fooby-l2 & {
            fill: $theme-color-l2;
        }

        .little-fooby-l3 & {
            fill: $theme-color-l3;
        }

        .little-fooby-l1.share-group &,
        .little-fooby-l1.teaser & {
            fill: $theme-color-l1;
        }

        .little-fooby-l2.share-group &,
        .little-fooby-l2.teaser & {
            fill: $theme-color-l2;
        }

        .little-fooby-l3.share-group &,
        .little-fooby-l3.teaser & {
            fill: $theme-color-l3;
        }
    }
}

.like--active {
    svg {
        // Fill-Animationen now with Greensock for IE11 compatibility
         @include icon-fill-anim;
    }
}

.bookmark--active {
    svg {
        // Fill-Animationen now with Greensock for IE11 compatibility
         @include icon-fill-anim;
    }
}

// Modifier for share-group in Teaser
.share-group--in-teaser {
    line-height: 1;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    justify-content: flex-end;

    .share-group__single-link {
        padding: rem-calc(5px);
        position: relative;
        right: rem-calc(-5px);
        display: inline-flex;
        align-items: center;
    }

    .bookmark-icon {
        top: 0;
    }

    .share-group__single {
        line-height: 1;

        &--grow {
            flex-grow: 1;
            text-align: left;

            &:first-child {
                .share-group__single-link {
                    margin-left: rem-calc(-8px);
                }
            }
        }

        &:not(:last-child) {
            margin-right: rem-calc(19px);
        }

        @media #{$medium-up} {
            &:not(:last-child) {
                margin-right: rem-calc(11px);
            }
        }
    }

    .share-group__single-share-icon,
    .share-group__single-share-copy {
        display: inline-block;
    }

    .share-group__single-share-copy {
        vertical-align: middle;
        margin-left: rem-calc(4px);

        @media #{$medium-up} {
            font-size: rem-calc(10px);
        }
    }

    .share-group__single-share-icon {
        vertical-align: middle;

        @media #{$large-up} {
            top: 0;
            margin-right: 0;
        }
    }
}

// icon list in share tooltip
.share-icon-list {
    @extend %inline-list;
    overflow: hidden;

    .facebook {
        background-color: #3b5998;
    }

    .messenger {
        background-color: #0086fc;
    }

    .instagram {
        background-color: #bc2a8d;
    }

    .pinterest {
        background-color: #c92228;

        .at_PinItButton {
            display: none;
        }
    }

    .twitter {
        background-color: #00aced;
    }

    .whatsapp {
        background-color: #00d55e;
    }

    .email {
        background-color: $color-stone-grey;
    }
}

.share-icon-list__item {
    margin-right: rem-calc(10px);
    margin-bottom: rem-calc(10px);

    &:last-child {
        margin-right: 0;
    }
}

// sass-lint:disable-all
.share-icon-list__link {
    display: block;
    width: rem-calc(44px) !important;   // because width can be overriden by addThis
    height: rem-calc(44px) !important;  // because height can be overriden by addThis
    border-radius: 100px;
    cursor: pointer;

    > svg {
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);

        * {
            fill: $color-white;
        }
    }
}
/* scss-lint:enable all */

.copy-link {
    label {
        display: block;
        font-size: rem-calc(10px);
        color: $color-stone-grey;
    }

    input {
        border-color: $color-light-grey;
        font-size: rem-calc(14px);
    }
}

// sass-lint:disable-all
input.copy-link__input {
    padding: rem-calc(10px) rem-calc(40px) rem-calc(10px) rem-calc(12px);
    width: 100%;
    border-color: $color-light-grey;
    font-size: rem-calc(14px);
}
/* scss-lint:enable all */

.copy-link__input-wrapper {
    position: relative;
}

.copy-link__success {
    position: absolute;
    left: 50%;
    bottom: 0;
    padding: 4px 8px;
    font-size: 12px;
    background-color: $color-white;
    border: 1px solid $color-stone-grey;
    transform: translate(-50%, 50%);
    opacity: 0;
    transition: opacity .2s ease-in-out;

    &.show-copied {
        opacity: 1;
    }
}

// sass-lint:disable-all
.copy-link__icon {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    margin-bottom: 0 !important;    // overwrite addThis styles
    width: 40px;
    height: 100%;
    cursor: pointer;
    transition: all $animation-time-default $animation-easing-default;

    > svg {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        * {
            fill: $color-stone-grey;
        }
    }

    &:hover {
        opacity: $hover-opacity;
    }

    &:active {
        transform: translateY(3%);
    }
}
/* scss-lint:enable all */
