// This is just to center the spinner

// html, body { height: 100%; }

// body {
//    display: flex;
//    align-items: center;
//    justify-content: center;
// }

// Here is where the magic happens

$offset: 60;
$duration: 1.4s;
$size: rem-calc(20px);

.loading-add {
    position: relative;
    height: $size;
    width: $size;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.loading-add__plus-base,
.loading-add__check-base {
    opacity: 0;
    transition: opacity .22s ease-in-out;
}

.loading-add__circle {
    transform-origin: center;
    stroke: $color-green;
}

.loading-add__plus,
.loading-add__check {
    transform-origin: center;
    fill: $color-green;
}

.loading-add--state {

    &-plus {
        .loading-add__plus-base {
            opacity: 1;
        }
    }

    &-spinning {

        .loading-add__circle-base {
            transform: rotate(0deg);
            animation: rotator $duration linear infinite;
        }

        .loading-add__circle {
            stroke-dasharray: $offset;
            stroke-dashoffset: $offset;
            animation: dash $duration ease-in-out infinite;
        }
    }

    &-check {
        .loading-add__check-base {
            opacity: 1;
        }
    }
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: $offset / 4;
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}
