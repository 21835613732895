/* COMPONENT PN-Navi
##################################################################################################################### */

.prev-next-navi {
    display: none;
    position: absolute;
    width: 100%;
    top: 0px;
    height: rem-calc(34px);
    justify-content: center;
    border-bottom: 1px solid $color-light-grey;

    @if ($little-fooby) {
        left: rem-calc(10px);
        width: calc(100% - 20px);

        @media screen and (min-width: 1024px) and (max-width: #{$screen-lg - 1}) {
            top: rem-calc(5px);
            left: rem-calc(16px);
            width: calc(100% - 32px);
        }

        @media #{$xlarge-up} {
            top: rem-calc(10px);
            left: rem-calc(25px);
            width: calc(100% - 50px);
        }
    } @else {
        left: 0;
        background-color: $color-white;
    }

    &.is-mobile {
        border-top: 1px solid $color-light-grey;
        position: static;
    }

    @media #{$medium-up} {
        height: rem-calc(50px);
    }

    @media #{$large-up} {
        &.is-mobile {
            display: none !important; // Hide mobile prev next on large or higher
        }
    }

    @media #{$medium-down} {
        &:not(.is-mobile) {
            display: none !important; // Hide normal prev next on mobile & tablet
        }
    }
}

.prev-next-navi__prev,
.prev-next-navi__overview,
.prev-next-navi__next {
    display: block;
    flex: 0 0 auto;
    color: $color-stone-grey;
    text-decoration: none;
    line-height: rem-calc(20px);
    font-size: rem-calc(11px);
    font-weight: bold;
    font-family: $font-family-montserrat;
    text-transform: uppercase;
    transition: color $animation-time-default $animation-easing-default;
    padding: rem-calc(7px) rem-calc($container-padding-small);
    white-space: nowrap;

    @media #{$medium-up} {
        padding: rem-calc(15px) rem-calc($container-padding-medium);
    }

    @media #{$large-up} {
        padding: rem-calc(15px) rem-calc(23px);
    }

    .svg-fill {
        fill: $color-stone-grey;
        transition: fill $animation-time-default $animation-easing-default;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: darken($color-stone-grey, 15%);

        .svg-fill {
            fill: darken($color-stone-grey, 15%);
        }
    }
}

.prev-next-navi__prev,
.prev-next-navi__next {
    flex: 0 0 calc(50% - 20px);

    svg {
        height: rem-calc(11px);
    }
}

.icon-next,
.icon-next-double,
.icon-prev,
.icon-prev-double {
    height: rem-calc(11px);
}

.icon-next,
.icon-prev {
    width: rem-calc(7px);
}

.icon-next-double,
.icon-prev-double {
    width: rem-calc(13px);
}

.prev-next-navi__next {
    text-align: right;
}

.prev-next-navi__center {
    display: block;
    flex: 1 1 auto;
}

.prev-next-navi__overview {
    padding: rem-calc(7px);
    height: rem-calc(34px);
    width: rem-calc(29px);
    margin: 0 auto;
    text-align: center;
    position: relative;

    svg {
        position: relative;
        top: rem-calc(2px);
        height: rem-calc(15px);
        width: rem-calc(15px);
    }

    @media #{$medium-up} {
        padding: rem-calc(15px);
        height: rem-calc(50px);
        width: rem-calc(45px);
    }
}

.prev-next-navi__icon-left,
.prev-next-navi__icon-right {
    position: relative;
    top: 1px;
}

.prev-next-navi__icon-left {
    margin-right: rem-calc(10px);
}

.prev-next-navi__icon-right {
    margin-left: rem-calc(10px);
}
