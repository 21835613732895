/* COMPONENT Searchbar
##################################################################################################################### */
/* scss-lint:disable QualifyingElement */
.searchbar {
    // no styled needed
}

// has to be qualified like this
input.searchbar__searchfield {
    width: 100%;
    border: 1px solid $color-light-grey;

    body:not(.search-overlay-active--home):not(.search-overlay-active) .main-search & {
        padding-left: rem-calc(48px);

        @media #{$small-only} {
            border: none;
            padding-top: rem-calc(22px);
            padding-bottom: rem-calc(18px);
        }
    }
}

.searchbar__swipelink,
.searchbar__searchtrigger,
.searchbar__swipedelimiter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: rem-calc(5px);
}

.searchbar__swipelink,
.searchbar__searchtrigger {
    transition: color $animation-time-default $animation-easing-default;

    &:hover,
    &:focus,
    &:active {
        color: $color-green;
    }

    .main-search & {
        transform: translateY(calc(-50% + 2px));
    }

    .search-overlay-active--home &,
    .search-overlay-active & {
        transform: translateY(-50%);
    }
}

.searchbar__swipelink,
.searchbar__swipedelimiter {
    .search-overlay-active--home &,
    .search-overlay-active & {
        display: none;
    }

    @media #{$medium-up} {
        display: none;
    }
}

.searchbar__swipedelimiter {
    padding: 0;
    height: rem-calc(30px);
    width: 1px;
    opacity: 0.3;
    background-color: $color-stone-grey;
    right: rem-calc(56px);
}

.searchbar__swipelink {
    right: rem-calc(12px);
    color: $color-stone-grey;
}

.searchbar__searchtrigger {
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    right: rem-calc(11px);
    color: $color-stone-grey;

    svg {
        display: block;
    }

    .main-search & {
        left: rem-calc(11px);
        right: auto;
    }

    .search-overlay-active--home &,
    .search-overlay-active & {
        left: auto;
        right: rem-calc(11px);
    }
}

.searchbar__btn {
    width: 100%;
}

.searchbar__cell,
.searchbar__cell-replacement {
    position: relative;
    margin-bottom: rem-calc(12px);
}

@media #{$medium-up} {
    .searchbar__table {
        display: table;
        width: 100%;
    }

    .searchbar__cell {
        display: table-cell;
        vertical-align: top;
        position: relative;
        margin-bottom: 0;

        &:not(:last-child) {
            padding-right: rem-calc(30px);
        }

        .searchbar__searchtrigger {
            right: rem-calc(45px);
        }
    }

    .searchbar__cell--min {
        width: 1px; // automatically adjustified for as wide as needed
        white-space: nowrap;
    }
}

.searchbar--myfooby {

    // has to be qualified like this
    input.searchbar__searchfield {
        //max-width: rem-calc(270px);
        float: right;
        font-size: rem-calc(12px);

        @media #{$small-only} {
            height: rem-calc(44px);
            border: 0 none;
            background-color: rgba($color-light-grey, .5);
        }

        @media #{$medium-up} {
            max-width: rem-calc(270px);
        }
    }

    .searchbar__searchtrigger {
        height: rem-calc(25px);
        width: rem-calc(25px);
        transform: translateY(-55%);

        svg {
            height: rem-calc(15px);
            width: rem-calc(15px);
        }
    }

    .searchbar__cell-replacement {
        @extend %clearfix;
        margin-bottom: 0;
    }
}
/* scss-lint:enable QualifyingElement */
