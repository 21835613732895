/* FONTS
##################################################################################################################### */

/**
 * https://google-webfonts-helper.herokuapp.com/fonts/
 */

/* montserrat-700 - latin */
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: local("Montserrat-Bold"),
        url("../assets/fonts/montserrat-v7-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/montserrat-v7-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* open-sans-regular - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Open Sans"), local("OpenSans"),
        url("../assets/fonts/open-sans-v13-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/open-sans-v13-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* open-sans-italic */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Open Sans Italic"), local("OpenSansItalic"),
        url("../assets/fonts/open-sans-italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/open-sans-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* open-sans-700 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans Bold"), local("OpenSans-Bold"),
        url("../assets/fonts/open-sans-v13-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/open-sans-v13-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* open-sans-700-italic */
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 700;
    src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
        url("../assets/fonts/open-sans-700-italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/open-sans-700-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* amatica-sc-700 - latin */
@font-face {
    font-family: "Amatica SC";
    font-style: normal;
    font-weight: 700;
    src: local("Amatica SC Bold"), local("AmaticaSC-Bold"),
        url("../assets/fonts/amatica-sc-v1-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/amatica-sc-v1-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* roboto-slab-regular - latin */
@font-face {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    src: local("Roboto Slab Regular"), local("RobotoSlab-Regular"),
        url("../assets/fonts/roboto-slab-v6-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/roboto-slab-v6-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* northernsoul-caps - latin */
@font-face {
    font-family: "NorthernSoul Caps";
    font-style: normal;
    font-weight: 400;
    src: local("NorthernSoul Caps"), local("NorthernSoul-Caps"),
        url("../assets/fonts/northernsoul-caps.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/northernsoul-caps.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}

/* northernsoul-script - latin */
@font-face {
    font-family: "NorthernSoul Script";
    font-style: normal;
    font-weight: 400;
    src: local("NorthernSoul Script"), local("NorthernSoul-Script"),
        url("../assets/fonts/northernsoul-script.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../assets/fonts/northernsoul-script.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
}
