/* COMPONENT Loader Pot
##################################################################################################################### */

$animation-time: 1.2s;
$size: rem-calc(60px);
$size-small: rem-calc(40px);

.loader-pot {
    position: relative;
    width: $size;
    height: $size;
    margin: rem-calc(20px) auto;
    color: inherit;

    &--small {
        width: $size-small;
        height: $size-small;

        // The last child inside of a col can't have a margin at the bottom
        margin: rem-calc(12px) auto;
        top: rem-calc(-6px);
    }

    &--no-shift {
        top: 0;
    }

    &--theme-dark {
        .stop-1 {
            stop-color: $color-dark-stone-grey;
        }
    }
}

.loader-pot__spoon-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    animation: rotate $animation-time infinite linear;
    transform-origin: 50% 71%;
    top: -21%;
}

.loader-pot__shine,
.loader-pot__pot {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
}

.loader-pot__spoon {
    display: block;
    z-index: 2;
    animation: negative-rotate $animation-time linear infinite;
}

@keyframes negative-rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-pot__shine {
    animation: rotate-shine $animation-time linear infinite;
}

@keyframes rotate-shine {
    0% {
        transform: rotate(-45deg);
    }

    100% {
        transform: rotate(315deg);
    }
}
