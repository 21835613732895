@import "../../../node_modules/izitoast/dist/css/iziToast";

.iziToast-wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    padding: 10px 20px;
    // pointer-events: none;

    @media #{$medium-up} {
        max-width: 650px;
    }

    @media #{$large-up} {
        max-width: 876px;
    }
}

.iziToast-target {
    padding-bottom: rem-calc(66px);
}


.iziToast.iziToast-theme-default,
.iziToast.iziToast-theme-default-image {
    // @extend .milli-title;
    padding: rem-calc(9px) rem-calc(12px) !important;
    padding-right: rem-calc(12px + 16px + 12px) !important;
    background-color: $color-dark-grey;
    margin-bottom: 10px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2), 0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    min-height: initial;

    @media #{$medium-up} {
        padding: rem-calc(13px) rem-calc(20px) !important;
        padding-right: rem-calc(20px + 16px + 12px) !important;
    }

    &:not(.iziToast-rtl) .iziToast-cover {
        border-radius: 0px;
    }

    & > .iziToast-cover {
        background-size: cover;
    }

    & > .iziToast-progressbar {
        background-color: $color-dark-grey;
    }

    & > .iziToast-progressbar > div {
        height: 2px;
        background-color: $color-green;
        border-radius: 0px;
    }

    & > .iziToast-progressbar {
        background-color: $color-dark-grey;
    }

    & > .iziToast-body {
        margin: 0;
        padding: 0;
        min-height: initial;
    }

    & > .iziToast-body,
    & > .iziToast-body .iziToast-message,
    & > .iziToast-body .iziToast-buttons > .iziToast-buttons-child {
        font-size: rem-calc(14px);
        line-height: 1.45;
    }

    & > .iziToast-body .iziToast-texts {
        margin-top: 0;
        font-family: $font-family-roboto;
        margin: rem-calc(3px) 0;
    }

    & > .iziToast-body .iziToast-buttons {
        font-family: $font-family-open-sans;
        color: $color-green;
        font-weight: bold;
        margin: rem-calc(3px) rem-calc(-6px);
    }

    & > .iziToast-body .iziToast-buttons > .iziToast-buttons-child {
        padding: 0;
        margin: 0 rem-calc(6px);
        border-radius: 0;
        letter-spacing: initial;
        background: none;
        color: $color-green;

        &:focus {
            box-shadow: none;
        }
    }

    & > .iziToast-body .iziToast-title,
    & > .iziToast-body .iziToast-message {
        color: $color-white;
    }

    & > .iziToast-body .iziToast-message {
        margin-bottom: 0;
    }

    & > .iziToast-close {
        background-image: url("data:image/svg+xml;base64,PCEtLSBSZXBsYWNlIHRoZSBjb250ZW50cyBvZiB0aGlzIGVkaXRvciB3aXRoIHlvdXIgU1ZHIGNvZGUgLS0+Cgo8c3ZnIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMkwxNCAxNFoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0yIDJMMTQgMTQiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCAyTDIgMTRaIiBmaWxsPSJibGFjayIvPgo8cGF0aCBkPSJNMTQgMkwyIDE0IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
        background-size: contain;
        background-position: center center;
        width: 16px;
        height: 16px;
        opacity: 1;
        top: rem-calc(12px);
        right: rem-calc(12px);
    }
}

.iziToast.iziToast-theme-default-image {
  &:not(.iziToast-rtl) .iziToast-cover {
    width: 50px !important;
  }

  & > .iziToast-body {
    margin-left: 50px !important;
  }
}
