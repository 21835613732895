/* COMPONENT Tooltip
##################################################################################################################### */

.tooltip-highlight {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    box-shadow: 0 0 0 999999px rgba(0,0,0,0.15);
    border-radius: rem-calc(30px);
    z-index: 101;
    opacity: 0;
    transition: opacity $animation-time-default $animation-easing-default;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);

    &--visible {
        opacity: 1;
        transition: all $animation-time-default $animation-easing-default;
    }
}

.tooltip {
    display: none;
    z-index: 102;
    color: $color-dark-grey;
    width: calc(100% - #{rem-calc(32px)});

    @media #{$medium-up} {
        width: rem-calc(400px);
    }

    .heading--h3 {
        color: inherit;
    }

    &__inner {
        margin-top: rem-calc(16px);
        opacity: 0;
        transform: translate3d(0, 15px, 0);
        transition: transform $animation-time-default $animation-easing-default,
                    opacity $animation-time-default $animation-easing-default;
        border: 1px solid darken($color-light-grey, 10%);
        background-color: $color-white;
        width: 100%;

        @if ($little-fooby) {
            border-radius: 6px;
        }
    }

    &__hr {
        height: 1px;
        background-color: darken($color-light-grey, 10%); // same as tooltip border color
    }

    &__title {
        font-size: rem-calc(13px);
        line-height: 1.416;
        color: $color-dark-stone-grey;
        font-weight: bold;
        margin: 0 0 rem-calc(12px);
        display: block;
    }

    &__content {
        position: relative;
    }

    &__content-padder {
        padding: rem-calc(20px);
    }

    &__content-inner &__content-padder + &__content-padder {
        padding-top: 0;
    }

    &__content-padder--slim {
        padding-top: rem-calc(15px);
        padding-bottom: rem-calc(15px);
    }

    &__content-padder--big {
        padding: rem-calc(55px) rem-calc(40px);
    }

    &__arrow {
        display: block;
        position: absolute;
        top: rem-calc(-8px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 rem-calc(7px) rem-calc(8px);
        border-color: transparent transparent $color-white;

        &::before {
            display: block;
            content: " ";
            position: absolute;
            top: rem-calc(-1px);
            left: rem-calc(-8px);
            border-width: 0 rem-calc(8px) rem-calc(9px);
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent transparent darken($color-light-grey, 12%);
            z-index: -1;
        }
    }

    &__close {
        position: absolute;
        top: rem-calc(12px);
        right: rem-calc(12px);
        appearance: none;
        background-color: transparent;
        padding: rem-calc(4px);
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    /* Styling when position on top of trigger element */

    &[x-placement="top"] &__inner {
        margin-top: 0;
        margin-bottom: rem-calc(16px);
        transform: translate3d(0, -15px, 0);
    }

    &[x-placement="top"] &__arrow {
        top: auto;
        bottom: rem-calc(-8px);
        border-width: rem-calc(8px) rem-calc(7px) 0;
        border-color: $color-white transparent transparent;

        &::before {
            top: auto;
            bottom: rem-calc(-1px);
            border-width: rem-calc(9px) rem-calc(8px) 0;
            border-color: darken($color-light-grey, 12%) transparent transparent;
        }
    }


    /* Open Variant */

    &--open &__inner,
    &--open[x-placement="top"] &__inner {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }


    /* With close button */
    &--with-close &__content {
        padding-right: rem-calc(33px);
    }


    /* Dark Theme variant */

    &--theme-dark &__inner {
        background-color: $color-dark-stone-grey;
        border: 1px solid $color-dark-stone-grey;
        color: $color-white;
    }

    &--theme-dark &__arrow {
        border-color: transparent transparent $color-dark-stone-grey;

        &::before {
            border-color: transparent transparent $color-dark-stone-grey;
        }
    }

    &--theme-dark[x-placement="top"] &__arrow {
        border-color: $color-dark-stone-grey transparent transparent;

        &::before {
            border-color: $color-dark-stone-grey transparent transparent;
        }
    }

    &--theme-dark &__close .svg-stroke {
        stroke: $color-white;
    }

    /* Dropdown variant */

    &--dropdown {
        width: auto;
        min-width: rem-calc(145px);
    }

    &--dropdown .tooltip__inner {
        border: none;
        margin-top: rem-calc(8px);
    }

    &--dropdown .tooltip__arrow {
        display: none;
    }

    &--dropdown .tooltip__content {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    /* Share variant */

    &--share {
        width: auto;
        // min-width: rem-calc(260px);
    }

    &--share &__content-padder {
        padding: rem-calc(10px);
    }

    /* Title variant */

    &--title {
        width: auto;
        z-index: 103;
    }

    &--title &__inner {
        margin-top: rem-calc(12px);
    }

    &--title &__content {
        padding: rem-calc(2px) rem-calc(8px);
        font-size: rem-calc(12px);
        line-height: 24 / 12;
        font-weight: bold;
    }

    /* Hint variant */

    &--hint,
    &--hint-recipe-overlay {
        width: auto;
        max-width: 85%;

        br {
            display: none;
        }

        @media #{$medium-up} {
            max-width: none;

            br {
                display: initial;
            }
        }
    }

    &--hint-recipe-overlay {
        max-width: 85%;

        @media #{$medium-up} {
            max-width: rem-calc(393px);
        }
    }
}
