.popular-recipe {
    display: flex;
    width: rem-calc(278px);
    background-color: $color-white;
    color: $color-dark-stone-grey;
    border: 1px solid $color-dark-offwhite;

    &__image-wrapper {
        position: relative;
        width: rem-calc(80px);
        min-height: rem-calc(78px); // 80px - 2px from vertical borders
        flex-shrink: 0;
        align-self: stretch;
    }

    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    &__text {
        padding: rem-calc(12px);
        padding-bottom: rem-calc(8px);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        background-color: $color-white;
    }

    &__title {
        font-family: $font-family-roboto;
        font-size: rem-calc(14px);
        line-height: (18/14);
        margin-bottom: auto;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__views {
        display: inline-flex;
        align-items: center;
        color: $color-fooby-orange;
    }

    &__views-icon {
        margin-right: rem-calc(4px);

        svg {
            display: block;
        }
    }

    &__views-text {
        font-size: rem-calc(12px);
        line-height: (16/12);
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}
