.dropdown {
    &__label {
        display: flex;
        align-items: center;
        appearance: none;
        font-weight: 700;
        font-size: rem-calc(12px);
        line-height: 1.25;
        letter-spacing: 0.5px;
        border: none;
        padding: 0;
        background-color: transparent;
        text-transform: uppercase;
        color: $color-desaturated-green;

        svg {
            transition: transform $animation-time-default $animation-easing-default;
        }

        &.tooltip-trigger--active svg {
            transform: rotate(180deg);
        }
    }
    &__label-little {
        display: flex;
        align-items: center;
        appearance: none;
        font-weight: 700;
        font-size: rem-calc(12px);
        line-height: 1.25;
        letter-spacing: 0.5px;
        border: none;
        padding: 0;
        background-color: transparent;
        text-transform: uppercase;
        color: #2B8294;;

        svg {
            transition: transform $animation-time-default $animation-easing-default;
        }

        &.tooltip-trigger--active svg {
            transform: rotate(180deg);
        }
    }

    // is inside a tooltip element not .dropdown directly

    &__options {
        list-style: none;
        padding: 0;
        margin: 0;
        padding: rem-calc(16px) rem-calc(28px) rem-calc(20px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__option-item {
        display: flex;
    }

    &__option-item:not(:last-child) &__option-button,
    &__option-item:not(:last-child) &__option-button-little {
        margin-bottom: rem-calc(16px);
    }

    &__option-button {
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0;
        flex-grow: 1;
        text-transform: uppercase;
        color: $color-dark-grey;
        font-family: $font-family-montserrat;
        font-size: rem-calc(11px);
        line-height: 1.25;
        letter-spacing: 5%;
        &.is-active,
        &[data-sort-dropdown-current] {
            color: $color-desaturated-green;
        }
    }

    &__option-button-little {
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0;
        flex-grow: 1;
        text-transform: uppercase;
        color: $color-dark-grey;
        font-family: $font-family-montserrat;
        font-size: rem-calc(11px);
        line-height: 1.25;
        letter-spacing: 5%;
        &.is-active,
        &[data-sort-dropdown-current] {
            color: #2B8294;
        }
    }
}
