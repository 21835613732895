/* COMPONENT border-logo-panel
##################################################################################################################### */

$border-padding-mobile: 8px;
$border-padding-tablet: 16px;
$border-padding-desktop: 20px;

.border-logo-panel {
    position: relative;
    padding: rem-calc(44px) rem-calc($border-padding-mobile);

    &::before {
        content: " ";
        position: absolute;
        display: block;
        top: rem-calc($border-padding-mobile);
        left: rem-calc($border-padding-mobile);
        bottom: rem-calc($border-padding-mobile);
        right: rem-calc($border-padding-mobile);
        border: 2px solid $color-white;
        z-index: 2;
    }

    &:not(.border-logo-panel--no-coop-logo) {
        &::before {
            border-bottom-width: 0; // bottom border with gap for logo is an image
        }

        &::after {
            content: " ";
            position: absolute;
            display: block;
            left: rem-calc($border-padding-mobile);
            bottom: rem-calc($border-padding-mobile);
            right: rem-calc($border-padding-mobile);
            height: 2px;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB9AAAAACAQMAAAA6p9WQAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAVdEVYdENyZWF0aW9uIFRpbWUANy8xOC8xNtRkie8AAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAGklEQVQYlWP4T3fwhwELsKe/Oxjob+Vg8ToA7kLIvhixHyQAAAAASUVORK5CYII=");
            background-size: rem-calc(800px);
            background-position: center;
            z-index: 2;

            @media #{$medium-up} {
                background-size: rem-calc(1000px);
            }
        }
    }

    @media #{$medium-up} {
        padding: rem-calc(54px) rem-calc($border-padding-tablet);

        &::before {
            top: rem-calc($border-padding-tablet);
            left: rem-calc($border-padding-tablet);
            bottom: rem-calc($border-padding-tablet);
            right: rem-calc($border-padding-tablet);
        }

        &:not(.border-logo-panel--no-coop-logo) {
            &::after {
                left: rem-calc($border-padding-tablet);
                bottom: rem-calc($border-padding-tablet);
                right: rem-calc($border-padding-tablet);
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAD6AAAAACAQMAAAB2LbFNAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAVdEVYdENyZWF0aW9uIFRpbWUANy8yMC8xNlZw29AAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAG0lEQVQokWP4P7IAA1Yw0K6iMxhx/h2N9P//AQ42uHJRqY1cAAAAAElFTkSuQmCC");
                background-size: rem-calc(2000px);
            }
        }
    }

    @media #{$xlarge-up} {
        padding: rem-calc(64px) rem-calc($border-padding-desktop);

        &::before {
            top: rem-calc($border-padding-desktop);
            left: rem-calc($border-padding-desktop);
            bottom: rem-calc($border-padding-desktop);
            right: rem-calc($border-padding-desktop);
        }

        &:not(.border-logo-panel--no-coop-logo) {
            &::after {
                left: rem-calc($border-padding-desktop);
                bottom: rem-calc($border-padding-desktop);
                right: rem-calc($border-padding-desktop);
            }
        }
    }
}

.border-logo-panel__logo-link {
    position: absolute;
    left: 50%;
    bottom: rem-calc(3px);
    transform: translateX(-50%);
    z-index: 5;

    &,
    svg {
        display: block;
        width: rem-calc(42px);
        height: rem-calc(13px);
    }

    @media #{$medium-up} {
        bottom: rem-calc(6px);

        &,
        svg {
            width: rem-calc(60px);
            height: rem-calc(18px);
        }
    }

    @media #{$xlarge-up} {
        bottom: rem-calc(10px);
    }
}

.border-logo-panel--page-header {
    // emptyrule
}

.border-logo-panel--theme-black {
    .animated-fill {
        fill: $color-dark-grey;
    }

    &::before {
        border-color: $color-dark-grey;
    }

    &:not(.border-logo-panel--no-coop-logo) {
        &::after {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB9AAAAACCAYAAAAAaUfTAAAAfklEQVRoQ+3ZsREAIAgEMFiF/WdiFd0B6Yz9c0eohKyqEx4BAgQIECBAgAABAgQIEBgKdHcOo2IE1gXsOdZJFSRAgAABAgQIECBAgMBXAulj+dW8NUuAAAECBAgQIECAAIF1AQf0dVIFHwTsOR7wRAkQIECAAAECBAgQIEAgLnyECs1K/K9KAAAAAElFTkSuQmCC");
        }
    }

    @media #{$medium-up} {
        &:not(.border-logo-panel--no-coop-logo) {
            &::after {
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAD6AAAAACCAYAAABM4yMOAAAAwUlEQVR4Xu3bQREAIAgEQKlC/0xU0QT+vXFJcLPwA6q791IECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAg8LzAzNTzIQUkcBFwn2A0CBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgkCFQFrwZjZKSAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAAECHtDNQLKA+4Tk7slOgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgMBPAgcmBgrNZgLl7gAAAABJRU5ErkJggg==");
            }
        }
    }

}
