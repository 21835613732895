/* ELEMENTS
##################################################################################################################### */

@-webkit-keyframes autofill {
    to {
        color: $color-dark-stone-grey;
        background: $color-white;
        font-family: $font-family-open-sans;
    }
}

/*

Index

1. html
2. body
3. Headings
  3.1 h1
  3.2 h2
  3.5 h3
4. Paragraphs
5. Lists
  5.1 Unordered List
  5.2 Ordered List
6. Hyperlinks
7. Form Elements
8. Horizontal Rule
9. blockquote
10. cite

*/


// 1. html
html {
    font-size: 100%;
    height: 100%;
    width: 100%;
    margin: 0;
}

// 2. body
body {
    font-size: 1rem;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    color: $color-dark-stone-grey;
    background-color: $color-white;
    font-family: $font-family-open-sans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 1;
    overflow-anchor: none;
}

// 3. Headings

// 3.1 h1
h1 {
    @extend %h1;
}

// 3.2 h2
h2 {
    @extend %h2;
}

// 3.3 h3
h3 {
    @extend %h3;
}

// 4. Paragraphs

p {
    @extend %p;
}

// 5. Lists

// 5.1 Unordered List
ul {
    @extend %ul;
}

// 5.2 Ordered List
ol {
    @extend %ol;
}

a {
    @extend %a;
}

// 7. Form Elements
input,
textarea,
select {
    @include appearance(none);

    &::-ms-clear {
        display: none;
    }
}

textarea,
select,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"] {
    // 50px height (like button) = 24 (line-height) + 12 * 2 (padding) + 1 * 2 (border)
    @extend %p;
    width: 100%;
    padding: rem-calc(12px) rem-calc(18px);
    background-color: $color-white;
    border-radius: 0;
    border: 1px solid $color-grey;
    color: $color-dark-stone-grey;
    text-overflow: ellipsis;
    margin: 0;
    outline: none;
    font-weight: normal;
    font-family: $font-family-open-sans;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        animation-name: autofill;
        animation-fill-mode: both;
    }

    &::placeholder {
        color: $color-stone-grey;
    }

    &:focus,
    &:active {
        border-color: $color-stone-grey
    }

    &.error::placeholder {
        color: $color-error;
    }

    &.error,
    &.error:focus {
        border-color: $color-error;
    }

    &[disabled],
    &[readonly] {
        background-color: $color-light-grey;
    }
}

textarea {
    resize: vertical;
    min-height: rem-calc(50px);
}

select {
    appearance: none;
    display: block;
    background-color: $color-white;
    background-image: url(data:image/svg+xml;base64,PHN2ZyAgd2lkdGg9IjEyIiBoZWlnaHQ9IjgiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiIHZpZXdCb3g9IjAgMCAxMiA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8dGl0bGU+RHJvcGRvd24gQXJyb3c8L3RpdGxlPiAgICA8ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MDUuMDAwMDAwLCAtNDYuMDAwMDAwKSIgZmlsbD0iI2NjY2NjYyIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8Zz4gICAgICAgICAgICAgICAgPGc+ICAgICAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTAuMDAwMDAwLCA0Mi4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMzMuMDAwMDAwLCAwLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iRHJvcGRvd24tQXJyb3ciIHBvaW50cz0iNjggMTEuNDE0MjEzNiA3My43MDcxMDY4IDUuNzA3MTA2NzggNzIuMjkyODkzMiA0LjI5Mjg5MzIyIDY4IDguNjE0ODA2OTQgNjMuNzA3MTA2OCA0LjI5Mjg5MzIyIDYyLjI5Mjg5MzIgNS43MDcxMDY3OCI+PC9wb2x5Z29uPiAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    background-position: right rem-calc(18px) center;
    background-repeat: no-repeat;

    &::-ms-expand {
        display: none;
    }
}

input[type="radio"],
input[type="checkbox"] {
    display: inline-block;
    border: 1px solid $color-grey;
    height: rem-calc(16px);
    width: rem-calc(16px);
    background-color: $color-white;
    transition: all $animation-time-fast $animation-easing-default;
    position: relative;
    cursor: pointer;
    vertical-align: top;

    &:checked {
        border-color: $checkbox-checked-background-color;
        background-color: $checkbox-checked-background-color;

        @if ($little-fooby) {
            .little-fooby-l2 & {
                border-color: $checkbox-checked-background-color-l2;
                background-color: $checkbox-checked-background-color-l2;
            }

            .little-fooby-l3 & {
                border-color: $checkbox-checked-background-color-l3;
                background-color: $checkbox-checked-background-color-l3;
            }
        }
    }

    &[disabled] {
        border-color: $color-grey;
        background-color: $color-light-grey;
    }

    &.big {
        height: rem-calc(26px);
        width: rem-calc(26px);
    }

    + label,
    + .label {
        font-size: rem-calc(14px);
        margin-left: rem-calc(9px);
        margin-top: -2px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
    }
}

input[type="radio"] {
    border-radius: 50%;
    box-shadow: inset 0 0 0 3px $color-white;

    &[disabled] {
        background-color: $color-light-grey;
        box-shadow: none;
    }

    &[disabled]:checked {
        background-color: $color-stone-grey;
        box-shadow: inset 0 0 0 3px $color-light-grey;
    }
}

input[type="checkbox"] {
    background-repeat: no-repeat;
    background-position: center center;

    &.big {
        background-size: 55%;
    }

    &.rounded {
        border-radius: 3px;
    }

    &:checked {
	    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5cHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgOSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPlBhdGggMjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iMTktU3Blei1CdXR0b25zLC1MaW5rcyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJTdHlsZWd1aWRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTIxLjAwMDAwMCwgLTE0NjcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiNGRkZGRkYiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTE4LjAwMDAwMCwgMTQ1OC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTIiIHBvaW50cz0iNCAxMi4yMTY3Njc4IDYuMzM2MzYzNzkgMTUuMzUzNjMxIDExLjExNzU2OCA5Ij48L3BvbHlsaW5lPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    }

    &:checked:disabled {
	    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5cHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgOSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPlBhdGggMjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iMTktU3Blei1CdXR0b25zLC1MaW5rcyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJTdHlsZWd1aWRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODA3LjAwMDAwMCwgLTE0NjcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiM5OTk5OTkiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0yMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODA0LjAwMDAwMCwgMTQ1OC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNS4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aC0yIiBwb2ludHM9IjQgNy4yMTY3Njc4NSA2LjMzNjM2Mzc5IDEwLjM1MzYzMSAxMS4xMTc1NjggNCI+PC9wb2x5bGluZT4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    }
}

// 8. Horizontal Rule
hr {
    @extend %clearfix;
    margin: rem-calc(30px) 0;
    width: rem-calc(50px);
    height: rem-calc(3px);
    border: 0 none;
    background: $color-stone-grey;
    display: block;
    float: left;
}

// 9. blockquote
blockquote {
    margin: 0;
}

// 10. cite
cite {
    font-style: normal;
}
