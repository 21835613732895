/* COMPONENT author-info-box
##################################################################################################################### */


.author-info-box__img-container {
    @include round-img-container;

    margin: 0 auto rem-calc(10px);
    width: 59%;

    @media #{$medium-up} {
        width: 100%;
    }
}

.author-info-box__name {
    margin-bottom: rem-calc(6px);
    color: $color-green;
    font-size: rem-calc(20px);
    font-weight: bold;
    text-align: center;
    line-height: 1.2;

    &:hover {
        color: $color-green;
        text-decoration: none;
    }

    @media #{$medium-up} {
        text-align: left;
    }
}

.author-info-box__copy {
    margin-bottom: rem-calc(20px);
    line-height: 1.5;
}

.author-info-box__icons {
    .social-media-list {
        text-align: center;
    }

    //.social-media-list__item {
    //    margin-right: rem-calc(25px);
    //    max-width: rem-calc(27px); // IE10+ fix. width:auto won't scale correctly on IE
    //
    //
    //    &:last-child {
    //        margin-right: 0;
    //    }
    //
    //    svg {
    //        // width: auto;
    //        // height: 20px;
    //        // max-width: 100%;
    //    }
    //}

    @media #{$medium-up} {
        padding: 0;

        .social-media-list {
            width: 100%;
            text-align: left;
        }

        //.social-media-list__item {
        //    svg {
        //        width: auto;
        //        height: rem-calc(25px); // IE10+ fix. width:auto won't scale correctly on IE
        //    }
        //}
    }

    @media #{$large-up} {
        //.social-media-list__item {
        //    svg {
        //        width: auto;
        //        height: 20px;
        //    }
        //}
    }
}
