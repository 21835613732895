/* COMPONENT MiniModal
##################################################################################################################### */

$mini-modal-image-width: 210px !default;
$mini-modal-image-width-large-up: 265px !default;
$mini-modal-image-height-mobile: 230px !default;

.mini-modal--active {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.mini-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: block;
    transition: transform 0s 1s;
    transform: translateX(-100%);

    .heading--h3 {
        margin-bottom: rem-calc(13px);

        @if ($little-fooby) {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: rem-calc(40px);
            line-height: 1;
        }
    }

    .social-media-list {
        @media #{$medium-up} {
            width: 50%;
        }

        @media #{$medium-only} {
            top: 0;
        }

        @media #{$large-only} {
            top: 0;
        }
    }

    [data-view-type] {
        display: none;
    }

    &__title-line {
        height: 1px;
        background-color: $color-dark-offwhite;
        margin: rem-calc(17px) rem-calc(-$container-padding-small) rem-calc(17px);

        @media #{$medium-up} {
            margin: rem-calc(21px) rem-calc(-30px) rem-calc(22px);
        }

        h3 + &,
        .heading--h3 + & {
            margin-top: rem-calc(3px);

            @media #{$medium-up} {
                margin-top: rem-calc(8px);
            }
        }
    }

    &__status-icon {
        margin-bottom: rem-calc(20px);
        margin-top: rem-calc(10px);
    }

    &--overflow-visible {
        .mini-modal__flex-flex {
            overflow: visible;
        }
    }

    &--grey-bg &__inner {
        background-color: $color-lighter-grey;
    }

    &--narrow &__content {
        @media #{$medium-up} {
            padding: rem-calc($container-padding-medium * 1.25) rem-calc($container-padding-medium);
            width: 586px;
        }
    }

    &.is-theme-dark &__inner,
    &--theme-dark &__inner {
        background-color: $color-dark-stone-grey;

        &, .heading--h3 {
            color: $color-white;
        }

        .loader-pot svg {
            fill: $color-white;
        }
    }

    &.is-theme-dark &__close,
    &--theme-dark &__close {
        .svg-stroke {
            stroke: $color-white;
        }
    }
}

.mini-modal__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba($color-dark-grey, .75);
    transition: opacity $animation-time-slow $animation-easing-fast;
    opacity: 0;
}

.mini-modal__content {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    max-width: rem-calc(780px);
    $temp: rem-calc($container-padding-small);
    $temp2: rem-calc($container-padding-small * 2);
    $temp3: rem-calc($container-padding-small * 1.25);
    width: 100%;

    //margin: rem-calc($container-padding-small * 1.25) rem-calc($container-padding-small);
    //transform: translate(calc(-50% - #{$temp}), calc(-50% - #{$temp3}));
    transform: translate(-50%, -50%);

    @media #{$medium-up} {
        $temp: rem-calc($container-padding-medium);
        $temp2: rem-calc($container-padding-medium * 2);
        $temp3: rem-calc($container-padding-medium * 1.25);
        //$temp3: rem-calc($container-padding-medium * 2.5);
        width: calc(100% - #{$temp2});
        //margin: rem-calc($container-padding-medium * 1.25) rem-calc($container-padding-medium);
        ////transform: translateX(calc(-50% - #{$temp}));
        //transform: translate(calc(-50% - #{$temp}), calc(-50% - #{$temp3}));
        transform: translate(-50%, -50%);

        //max-height: calc(100vh - #{$temp3});
    }

    @media #{$large-up} {
        $temp: rem-calc($container-padding-large);
        $temp2: rem-calc($container-padding-large * 2);
        $temp3: rem-calc($container-padding-large * 1.25);
        //$temp3: rem-calc($container-padding-large * 2.5);
        width: calc(100% - #{$temp2});
        //margin: rem-calc($container-padding-large * 1.25) rem-calc($container-padding-large);
        ////transform: translateX(calc(-50% - #{$temp}));
        //transform: translate(calc(-50% - #{$temp}), calc(-50% - #{$temp3}));
        transform: translate(-50%, -50%);

        //max-height: calc(100vh - #{$temp3});
    }
}

.mini-modal__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $color-white;
    //min-height: rem-calc(300px);
    opacity: 0;
    transform: $modal-zoom-scale;
    transform-origin: center center;
    transition: opacity .5s $animation-easing-default,
                transform .5s cubic-bezier(.150, .865, .355, .990);
    will-change: opacity, transform;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    @media #{$medium-up} {
        height: auto;

        @if ($little-fooby) {
            clip-path: url(#modalBackground);
        }
    }
}

.mini-modal__flex {
    position: relative;
    z-index: 1;

    // flex stuff
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    & > * {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    $temp3: rem-calc($container-padding-small * 2.5);
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    padding: rem-calc($container-padding-small);

    @media #{$medium-up} {
        $temp3: rem-calc($container-padding-medium * 2.5);
        max-height: calc(100vh - #{$temp3});
        max-height: calc(var(--vh, 1vh) * 100 - #{$temp3});
        padding: rem-calc(30px);
    }

    @media #{$large-up} {
        $temp3: rem-calc($container-padding-large * 2.5);
        max-height: calc(100vh - #{$temp3});
        max-height: calc(var(--vh, 1vh) * 100 - #{$temp3});
    }
}

.mini-modal__scrollblocker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.mini-modal__flex-fix {
    position: relative;
    z-index: 3;

    // grow, shrink, basis
    // doesn't grow nor whrink
    flex: 0 0 auto;
}

.mini-modal__flex-flex {
    position: relative;
    z-index: 3;

    // grow, shrink, basis
    // will not grow, but shrink when the parents gets too small in height
    flex: 1 1 auto;

    -webkit-overflow-scrolling: touch;

    &--centered &-inner {
        justify-content: center;
    }
}

.mini-modal__flex-flex-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.mini-modal__flex-flex--scrollable {

    top: -10px;
    margin-bottom: -10px;
    padding-top: 10px;
    padding-bottom: 10px;

    &::after,
    &::before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        height: 11px;
        z-index: 4;
    }

    &::before {
        top: -2px;
        background-image: linear-gradient($color-white, rgba($color-white, 0));
    }

    &::after {
        bottom: -2px;
        background-image: linear-gradient(rgba($color-white, 0), $color-white);
    }

    .mini-modal__flex-flex-inner {
        @extend %clearfix;

        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0%;
        left: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.mini-modal__close {
    position: absolute;
    padding: rem-calc(15px) rem-calc(15px) rem-calc(5px) rem-calc(5px);
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 2;
    background-color: transparent;

    height: rem-calc(35px);
    width: rem-calc(35px);

    svg {
        height: rem-calc(15px);
        width: rem-calc(15px);
    }

    @media #{$medium-up} {
        top: rem-calc(15px);
        right: rem-calc(15px);
        height: rem-calc(40px);
        width: rem-calc(40px);

        svg {
            height: rem-calc(20px);
            width: rem-calc(20px);
        }
    }
}

.mini-modal__left-image {
    background: center center / cover;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: none;

    @media #{$medium-up} {
        display: block;
        width: rem-calc($mini-modal-image-width);
    }

    @media #{$large-up} {
        width: rem-calc($mini-modal-image-width-large-up);
    }
}

.mini-modal--open {

    transition-delay: 0s;
    transform: translateX(0);

    .mini-modal__background {
        //transition: opacity $animation-time-default $animation-easing-fast .1s;
        transition-delay: .1s;
        opacity: 1;
    }

    .mini-modal__inner {
        @extend %global--fade-in-block;
        transition-delay: $animation-time-slow;
    }
}

.mini-modal--large-paddings {
    .mini-modal__flex {
        @media #{$medium-up} {
            padding: rem-calc(35px) rem-calc(30px);
        }

        @media #{$large-up} {
            padding: rem-calc(35px) rem-calc(30px);
        }
    }
}

.mini-modal--huge-paddings {
    .mini-modal__flex {
        padding: rem-calc(45px) rem-calc(30px);

        @media #{$medium-up} {
            padding: rem-calc(60px);
        }

        @media #{$large-up} {
            padding: rem-calc(60px) rem-calc(75px);
        }
    }

    .mini-modal__title-line {
        margin-left: rem-calc(-60px);
        margin-right: rem-calc(-60px);

        @media #{$large-up} {
            margin-left: rem-calc(-75px);
            margin-right: rem-calc(-75px);
        }
    }
}

.mini-modal--semi-slim {
    .mini-modal__content {
        @media #{$medium-up} {
            max-width: rem-calc(510px);
        }
    }
}

.mini-modal--extra-slim {
    .mini-modal__content {
        @media #{$medium-up} {
            max-width: rem-calc(330px);
        }

        @media #{$large-up} {
            max-width: rem-calc(360px);
        }
    }
}

.mini-modal--has-image {
    .mini-modal__flex {
        @media #{$medium-up} {
            margin-left: rem-calc($mini-modal-image-width);
        }

        @media #{$large-up} {
            margin-left: rem-calc($mini-modal-image-width-large-up);
        }
    }
}

.mini-modal--has-image-mobile {
    .mini-modal__flex {
        @media #{$small-only} {
            padding-top: rem-calc($mini-modal-image-height-mobile + 25px);
        }
    }

    .mini-modal__left-image {
        background: top center / cover;
        display: block;
        width: 100%;
        height: rem-calc($mini-modal-image-height-mobile);

        @media #{$medium-up} {
            width: rem-calc($mini-modal-image-width);
            height: auto;
        }

        @media #{$large-up} {
            width: rem-calc($mini-modal-image-width-large-up);
        }
    }

    .mini-modal__close {
        background-color: transparent;
    }
}

.mini-modal--static-height {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transition: transform 0s 1s, background-color $animation-time-slow $animation-easing-fast;

    .mini-modal__background {
        background-color: transparent;
        transition: none;
    }

    .mini-modal__inner {
        height: auto;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);

        @media #{$medium-up} {
            min-height: 0;
            margin: rem-calc(20px) auto;
        }

        @media #{$large-up} {
            margin: rem-calc(30px) auto;
        }
    }

    .mini-modal__content {
        position: relative;
        left: 0;
        top: 0;
        margin: auto;
        transform: none;
        flex-shrink: 0;
    }

    .mini-modal__flex {
        max-height: none;
    }

    &.mini-modal--open {
        transition: transform 0s 0s, background-color $animation-time-slow .1s;
        transform: translateX(0);
        background-color: rgba($color-dark-grey, .75);
    }
}
