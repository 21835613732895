.decoration-line {
    width: 100%;
    height: rem-calc(10px);
    background-image: url('../assets/images/little-fooby/decoration-line.svg');
    background-repeat: repeat-x;
    background-position: top center;

    &--cookingview {
        height: rem-calc(6px);
        background-image: url('../assets/images/little-fooby/cookingview-decoration-line.svg');
    }
}

.decoration {
    position: relative;
    width: 100%;
    height: 100%;
    border: 3px solid #fff;
    border-image-source: url('../assets/images/little-fooby/borders/recipe-little-border.svg');
    border-image-slice: 11;
    border-image-width: 11px;
    border-image-repeat: round;

    @media #{$medium-up} {
        border-image-source: url('../assets/images/little-fooby/borders/recipe-little-border-large.svg');
        border-image-slice: 13;
        border-image-width: 13px;
    }
}
