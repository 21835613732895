/* COMPONENT C5.1 Page Header Standard
##################################################################################################################### */

.page-header-standard {
    background-color: $color-white;
    overflow: hidden;

    @media #{$medium-up} {
        background-color: $panel-background-color;
    }

    &__hero-image-wrapper {
        width: 100%;
        padding: 0;
        top: rem-calc(50px);
        background-color: $color-white;
        left: 0;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        padding-top: 66.666%;

        @media #{$small-only} {
            // force this on mobile to make sure parallax is disabled
            transform: translate3d(0, 0, 0) !important;
        }

        @if ($little-fooby) {
            background-color: $panel-background-color;
        } @else {
            background-color: $color-white;
        }

        @media #{$medium-up} {
            position: fixed;
            will-change: transform;
            top: rem-calc(70px);

            .foobyapp & {
                top: 0;
                padding-top: calc(66.666% + 70px);
            }
        }

        @media #{$large-up} {
            top: rem-calc(70px);
            height: calc(100vh - 70px);
            padding: 0 rem-calc(20px) rem-calc(20px);

            .foobyapp & {
                height: 100vh;
                padding: rem-calc(20px);
            }

            @if ($little-fooby) {
                background-color: $color-white;
            }
        }

        @media #{$xlarge-up} {
            top: rem-calc(100px);
            height: calc(100vh - 100px);
        }
    }

    &__hero-image-wrapper-spacer {
        width: 100%;
        padding: 0;
        left: 0;
        padding-top: 66.666%;
        display: none;

        @media #{$medium-up} {
            display: block;
        }

        @media #{$large-up} {
            display: none;
        }
    }

    &__hero-image {
        background: transparent no-repeat center center / cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        &::after {
            content: " ";
            position: absolute;
            top: rem-calc(7px);
            right: rem-calc(7px);
            bottom: rem-calc(7px);
            left: rem-calc(7px);

            @if ($little-fooby == false) {
                border: 2px solid $color-white;
            }
        }

        @media #{$medium-up} {
            &::after {
                top: rem-calc(20px);
                right: rem-calc(20px);
                bottom: rem-calc(20px);
                left: rem-calc(20px);
            }
        }

        @media #{$large-up} {
            top: 0;
            right: rem-calc(20px);
            bottom: rem-calc(20px);
            left: rem-calc(20px);

            .foobyapp & {
                top: rem-calc(20px);
            }

            &::after {
                top: rem-calc(20px);
                right: rem-calc(20px);
                bottom: rem-calc(20px);
                left: rem-calc(20px);
            }
        }
    }

    &__hero-image-image {
        @extend %header-image;
    }

    &__panel-wrapper {
        position: relative;

        @if ($little-fooby) {
            margin-top: rem-calc(-34px);
        } @else {
            margin-top: rem-calc(-17px);
        }

        @media #{$medium-up} {
            margin-top: rem-calc(-63px);

            .foobyapp & {
                margin-top: rem-calc(7px);
            }
        }

        @media #{$large-up} {
            @if ($little-fooby) {
                margin-top: calc(100vh - 70px - 115px);

                .foobyapp & {
                    margin-top: calc(100vh - 115px);
                }
            } @else {
                margin-top: calc(100vh - 70px - 105px);

                .foobyapp & {
                    margin-top: calc(100vh - 105px);
                }
            }
        }

        @media #{$xlarge-up} {
            margin-top: calc(100vh - 100px - 105px);

            .foobyapp & {
                margin-top: calc(100vh - 105px);
            }
        }
    }

    &__panel {
        position: relative;

        @if ($little-fooby) {
            padding: rem-calc(30px) 0 rem-calc(20px);
        } @else {
            padding: rem-calc(26px) 0 rem-calc(30px);
        }

        @if ($little-fooby) {
            background-size: 100% 100%;
            background-image: url('../assets/images/little-fooby/backgrounds/header-panel.svg');
        } @else {
            background-color: $color-white;
        }

        &.page-header-standard__panel__event {
            @if ($little-fooby) {
                padding: rem-calc(30px) 0 rem-calc(20px);
            } @else {
                padding: rem-calc(26px) 0 rem-calc(30px);
            }
        }

        @media #{$medium-up} {
            @if ($little-fooby) {
                background-image: url('../assets/images/little-fooby/backgrounds/header-panel-medium.svg');
                padding-top: rem-calc(35px);
                padding-bottom: rem-calc(40px);
            } @else {
                padding-top: rem-calc(23px);
                padding-bottom: rem-calc(60px);
            }
        }

        @media #{$xlarge-up} {
            @if ($little-fooby) {
                padding-top: rem-calc(35px);
                padding-bottom: rem-calc(40px);
            } @else {
                padding-top: rem-calc(22px);
                padding-bottom: rem-calc(30px);
            }
        }

        @if ($little-fooby) {
            &--little-fooby-home {
                padding: rem-calc(50px) 0 rem-calc(20px);

                @media #{$medium-up} {
                    padding: rem-calc(80px) 0 rem-calc(60px);
                }
            }
        }

        .tag-cloud {
            display: none;

            @media #{$medium-up} {
                display: flex;
            }

            .heading--h3 {
                width: auto;
                font-weight: normal;
                font-size: inherit;
                margin-right: rem-calc(12px);
                font-family: $font-family-roboto;

                @media #{$large-up} {
                    margin-right: rem-calc(24px);
                }
            }

            .tag-cloud__inner {
                flex-grow: 1;
                padding-top: 0;
            }
        }

        .floating-title {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0 auto;

            @if ($little-fooby) {
                top: 10px;
            } @else {
                top: 0;
            }
        }

        // Home Title Line for Little Fooby
        @if ($little-fooby) {
            .title-lines {
                top: rem-calc(-140px);
                left: 50%;
                transform: rotate(-5deg) translateX(-50%);

                @media #{$medium-up} {
                    top: rem-calc(-188px);
                }

                &__line {
                    line-height: 1;
                }

                &__line--1 {
                    font-size: rem-calc(70px);
                    margin-bottom: rem-calc(-31px);
                    margin-left: rem-calc(24px);
                    padding-top: rem-calc(3px);
                    padding-bottom: rem-calc(23px);
                    padding-left: rem-calc(23px);
                    padding-right: rem-calc(20px);
                    color: $color-white;

                    @media #{$medium-up} {
                        font-size: rem-calc(95px);
                        margin-bottom: rem-calc(-38px);
                        margin-left: rem-calc(25px);
                        padding-top: rem-calc(6px);
                        padding-bottom: rem-calc(18px);
                        padding-left: rem-calc(30px);
                        padding-right: rem-calc(30px);
                    }

                    &::before {
                        background-image: url('../assets/images/little-fooby/backgrounds/home-title-lines/line1.svg');
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        right: rem-calc(-33px);
                        top: rem-calc(-2px);
                        z-index: -2;
                        background-image: url('../assets/images/little-fooby/backgrounds/home-title-lines/spoon.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: rem-calc(62px);
                        height: rem-calc(115px);

                        @media #{$medium-up} {
                            right: rem-calc(-52px);
                            top: rem-calc(-25px);
                            width: rem-calc(102px);
                            height: rem-calc(187px);
                        }
                    }
                }

                &__line--2 {
                    // transform creates new z-index and background of line 1 could overlap this line
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: -1;

                    font-size: rem-calc(40px);
                    color: $color-lf-magenta;
                    padding-top: rem-calc(8px);
                    padding-bottom: rem-calc(14px);
                    padding-left: rem-calc(25px);
                    padding-right: rem-calc(22px);

                    &::before {
                        background-image: url('../assets/images/little-fooby/backgrounds/home-title-lines/line2.svg');
                    }

                    @media #{$medium-up} {
                        font-size: rem-calc(52px);
                        padding-top: rem-calc(17px);
                        padding-bottom: rem-calc(17px);
                        padding-left: rem-calc(35px);
                        padding-right: rem-calc(35px);
                    }
                }
            }
        }
    }

    &__panel-title {
        text-align: center;
    }

    &__countdown {
        text-align: center;
        font-size: rem-calc(12px);
    }

    &__countdown-icon {
        display: inline-block;
        vertical-align: top;
        margin-top: 2px;
        margin-right: 2px;
    }

    &__panel-sub-title,
    &__panel-copy,
    &__avatar-copy p {
        padding-left: rem-calc(15px);
        padding-right: rem-calc(15px);

        @media #{$medium-up} {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__panel-copy > p {
        @extend %paragraph--copy-intro;
    }

    &__grey-space {
        height: rem-calc(20px);
        background-color: $panel-background-color;

        @media #{$medium-up} {
            height: rem-calc(30px);
        }
    }

    &__meta-wrapper {
        width: 100%;

        .h-table-cell {
            vertical-align: middle;
        }

        @media #{$small-only} {
            display: block;
        }
    }

    &__avatar-wrapper {
        .author-info:not(:last-child) {
            margin-bottom: rem-calc(26px);

            @media #{$medium-up} {
                margin-bottom: rem-calc(20px);
            }
        }

        @media #{$small-only} {
            display: block;
            margin-bottom: rem-calc(30px);

            .h-table {
                width: 100%;
                display: block;
            }

            .h-table-cell {
                display: block;
            }
        }
    }

    &__share-wrapper {
        text-align: right;

        @media #{$small-only} {
            display: block;
        }

        @media #{$medium-up} {
            .share-group {
                float: right;
                position: relative;
                top: 1px;
            }
        }
    }

    @if ($little-fooby) {
        &__image-deco-wrapper {
            z-index: 3;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: rem-calc(6px) rem-calc(3.5px);

            @media #{$medium-up} {
                padding: $border-space-tablet;
            }

            @media #{$large-up} {
                padding: $border-space-desktop (20px + 16px) (20px + 16px);
            }
        }
    }

    /** Variants **/

    /* No parallax variant */

    &--no-parallax &__hero-image-wrapper-spacer {
        display: none;
    }

    &--no-parallax &__hero-image-wrapper {
        position: static;
        will-change: none;

        @media #{$large-up} {
            height: calc(100vh - 70px);
            padding: 0 rem-calc(20px) rem-calc(20px);

            .foobyapp & {
                height: 100vh;
                padding: rem-calc(20px);
            }
        }

        @media #{$xlarge-up} {
            height: calc(100vh - #{rem-calc(100px)});
        }
    }

    &--no-parallax &__hero-image-outer {
        @media #{$medium-only} {
            background-color: $color-white;
            padding-bottom: rem-calc(20px);
        }
    }

    &--no-parallax &__panel-wrapper {
        @media #{$medium-up} {
            margin-top: rem-calc(-63px);
        }

        @media #{$large-up} {
            @if ($little-fooby) {
                margin-top: rem-calc(-115px);
            } @else {
                margin-top: rem-calc(-105px);
            }
        }
    }


    /* Meat header variant */
    &--meat .meat-selection {
        position: relative;
    }

    &--meat &__hero-image-wrapper {
        position: absolute;
        top: 0;
        height: calc(100% + #{rem-calc(50px)});

        @media #{$large-up} {
            height: 100%;
            min-height: calc(100vh - #{rem-calc(70px)});
        }

        @media #{$xlarge-up} {
            min-height: calc(100vh - #{rem-calc(100px)});
        }

        @media #{$xlarge-up} {
            top: 0;
            height: 100%;
        }
    }

    &--meat &__panel-wrapper {
        margin-top: 0;
    }

    &--meat &__content {
        position: relative;
        min-height: calc(100vh - #{rem-calc(50px + 85px)});
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem-calc(20px);

        @media #{$medium-up} {
            padding: rem-calc(40px);
        }

        @media #{$large-up} {
            min-height: calc(100vh - #{rem-calc(70px + 105px)});
            padding: rem-calc(40px) rem-calc(60px);
            max-height: calc(100vh - #{rem-calc(70px + 105px)});
        }

        @media #{$xlarge-up} {
            min-height: calc(100vh - #{rem-calc(100px + 105px)});
            max-height: calc(100vh - #{rem-calc(100px + 105px)});
        }
    }


    /* No grey space on mobile variant */

    &--no-grey-space-mobile &__grey-space {
        @media #{$small-only} {
            display: none;
        }
    }


    /* Little Fooby Home header variant */

    @if ($little-fooby) {
        &--little-fooby-home &__hero-image-wrapper,
        &--little-fooby-home &__hero-image-wrapper-spacer {
            @media #{$small-only} {
                height: 75vh;
            }
        }
    }
}
