.scroll-navigation-dragging * {
    -webkit-user-drag: none !important;
    -webkit-app-region: no-drag !important;
    user-select: none !important;
    cursor: grabbing !important;
}

$scroll-navigation-button-width: 25px;

.scroll-navigation {
    &__section {
        display: flex;
        flex-direction: column;
    }

    &__nav {
        display: flex;
        position: sticky;
        z-index: 98;
        height: 52px;
        top: 51px;
        background-color: $color-light-pale-green;
        font-size: rem-calc(12px);
        color: $color-green;
        letter-spacing: rem-calc(2px);
        text-transform: uppercase;
        font-family: $font-family-montserrat;
        font-weight: bold;
        overflow: hidden;

        @media #{$xlarge-up} {
            top: 61px;
        }

        /* Shadows visibility */

        &.overflow .scroll-navigation__shadow-left,
        &.overflow .scroll-navigation__shadow-right {
            visibility: visible;
            opacity: 1;
        }

        &.reached-left .scroll-navigation__shadow-left,
        &.reached-right .scroll-navigation__shadow-right {
            visibility: hidden;
            opacity: 0;
        }

        /* Arrows visibility */

        &.overflow .scroll-navigation__prev,
        &.overflow .scroll-navigation__next {
            visibility: visible;
            opacity: 1;
        }

        &.reached-left .scroll-navigation__prev,
        &.reached-right .scroll-navigation__next {
            visibility: hidden;
            opacity: 0;
        }
    }

    &__shadow-left,
    &__shadow-right {
        z-index: 1;
        position: absolute;
        top: 0;
        max-width: rem-calc(146px);
        pointer-events: none;
        height: 100%;
        width: rem-calc(($container-padding-small * 2) + $scroll-navigation-button-width);
        transition: all $animation-time-slow $animation-easing-default;
        visibility: hidden;
        opacity: 0;

        @media #{$medium-up} {
            width: rem-calc($container-padding-medium + $scroll-navigation-button-width);
        }

        @media #{$large-up} {
            width: remc-calc($container-padding-large + $scroll-navigation-button-width);
        }

        @media #{$xlarge-up} {
            width: calc((100vw - (100vw - 100%) - #{rem-calc($max-container-width - $container-padding-large * 2)}) / 2);
        }
    }

    &__shadow-left {
        left: 0;
        background: linear-gradient(90deg, rgba($color-light-pale-green, 1) 0%, rgba($color-light-pale-green, 1) $scroll-navigation-button-width, rgba($color-light-pale-green, 0) 100%);

        // @media #{$xlarge-up} {
        //     background-image: linear-gradient(270deg, rgba($color-light-pale-green, 0.00) 30%, $color-light-pale-green 100%);
        // }
    }

    &__shadow-right {
        right: 0;
        background: linear-gradient(270deg, rgba($color-light-pale-green, 1) 0%, rgba($color-light-pale-green, 1) $scroll-navigation-button-width, rgba($color-light-pale-green, 0) 100%);

        // @media #{$xlarge-up} {
        //     background-image: linear-gradient(270deg, $color-light-pale-green 30%, rgba($color-light-pale-green, 0) 100%);
        // }
    }

    &__prev,
    &__next {
        z-index: 2;
        position: absolute;
        top: 50%;
        appearance: none;
        border: none;
        height: rem-calc(40px);
        width: rem-calc($scroll-navigation-button-width);
        transition: all $animation-time-slow $animation-easing-default;
        transform: translateY(-50%);
        visibility: hidden;
        opacity: 0;
    }

    &__prev {
        left: 0;

        svg {
            transform: rotate(90deg);
            margin-left: -1px;
        }
    }

    &__next {
        right: 0;

        svg {
            transform: rotate(-90deg);
            margin-left: 1px;
        }
    }

    .container {
        width: 100%;
        position: relative;
        margin-left: 0;
        margin-right: 0;

        @media #{$xlarge-up} {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__list-wrapper {
        -webkit-user-drag: none !important;
        -webkit-app-region: no-drag !important;
        user-select: none;
        position: absolute;
        cursor: pointer;
        transition: transform $animation-time-slow $animation-easing-default;

        .scroll-navigation-dragging & {
            transition: none;
        }
    }

    &__list {
        counter-reset: scroll-navigation;
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__list-link {
        display: flex;
        align-items: center;
        line-height: 1.166;
        transition: color $animation-time-fast $animation-easing-default;
        padding: rem-calc(16px) 0;

        &:hover,
        &:focus,
        &:active {
            color: $color-dark-green;
            outline: none;
        }

        &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem-calc(20px);
            height: rem-calc(20px);
            line-height: rem-calc(20px);
            text-align: center;
            border-radius: 50%;
            counter-increment: scroll-navigation;
            content: counter(scroll-navigation);
            margin-right: rem-calc(8px);
            font-size: rem-calc(10px);
            color: $color-white;
            background-color: $color-green;
            letter-spacing: 0;
            transition: background-color $animation-time-fast $animation-easing-default;
        }

        &:hover::before,
        &:focus::before,
        &:active::before {
            background-color: $color-dark-green;
        }
    }

    &__list-item {
        // &:first-child {
        //     padding-left: $container-padding-small;

        //     @media #{$medium-up} {
        //         padding-left: $container-padding-medium;
        //     }

        //     @media #{$large-up} {
        //         padding-left: $container-padding-large;
        //     }

        //     @media #{$xlarge-up} {
        //         padding-left: calc((100vw - (100vw - 100%) - #{rem-calc($max-container-width - $container-padding-large * 2)}) / 2);
        //     }
        // }

        // &:last-child {
        //     padding-right: $container-padding-small;

        //     @media #{$medium-up} {
        //         padding-right: $container-padding-medium;
        //     }

        //     @media #{$large-up} {
        //         padding-right: $container-padding-large;
        //     }

        //     @media #{$xlarge-up} {
        //         padding-right: calc((100vw - (100vw - 100%) - #{rem-calc($max-container-width - $container-padding-large * 2)}) / 2);
        //     }
        // }

        &:not(:last-child) {
            margin-right: rem-calc(30px);
        }
    }

    &__list-item.active &__list-link {
        color: $color-fooby-orange;

        &::before {
            background-color: $color-fooby-orange;
        }
    }
}
