/* COMPONENT Language Nav
##################################################################################################################### */

// sass-lint:disable-all

.language-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 rem-calc(20px);
    z-index: 91;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__fooby-link {
        display: block;
        padding: 0 rem-calc(20px);
        background-color: rgba($color-green, 0.2);
        color: $color-green;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.2px;
        font-family: $font-family-montserrat;
        line-height: rem-calc(30px);
        font-size: rem-calc(12px);
        margin-left: rem-calc(10px);

        @media #{$medium-up} {
            display: none;
        }
    } 
}

.footer .language-nav {
    display: none;
}

.language-nav__l1-list {
    @extend %unstyled-list;
}

.language-nav__l1-item {
    float: left;
    line-height: rem-calc(50px);

    &:not(:last-child) {
        margin-right: rem-calc(30px);
    }

    @media #{$medium-up} {
        line-height: rem-calc(65px);

        &:not(:last-child) {
            margin-right: rem-calc(40px);
        }
    }
}

.language-nav__l1-link,
.language-nav__footer-text-wrapper {
    font-size: rem-calc(15px);
    line-height: rem-calc(50px);
    font-family: $font-family-open-sans;
    vertical-align: middle;
}

.language-nav__l1-link {
    color: $color-dark-grey;
    text-decoration: none;
    transition: color $animation-time-default $animation-easing-default;
}

.language-nav__l1-link:hover,
.language-nav__l1-link:active,
.language-nav__l1-link:focus {
    text-decoration: none;
    color: $color-green;
}

.language-nav__l1-link--state-active {
    text-decoration: none;
}

.language-nav__l1-link:active,
.language-nav__l1-link:focus,
.language-nav__l1-link--state-active {
    font-weight: bold;
}

.language-nav__touch-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
    }
}

.language-nav__footer-text-wrapper {
    font-weight: bold;
    display: none;
}

@media #{$xlarge-up} {
    body:not(.touch) {
        .header .language-nav {
            display: none;
        }

        .footer .language-nav {
            display: block;
            position: relative;
            padding: 0;
            float: left;
            top: 1px;
            bottom: inherit;
            left: inherit;
        }

        .language-nav__touch-icon-wrapper {
            display: none;
        }

        .language-nav__footer-text-wrapper {
            display: inline;
        }

        .language-nav__l1-item {
            &:not(:last-child) {
                margin-right: rem-calc(25px);
            }
        }

        .language-nav__l1-item,
        .language-nav__l1-link,
        .language-nav__footer-text-wrapper {
            font-size: 14px;
            line-height: rem-calc(30px);
        }

        .language-nav__l1-link,
        .language-nav__footer-text-wrapper {
            color: $color-white;
            transition: color $animation-time-default $animation-easing-default;
        }

        .language-nav__l1-link:hover,
        .language-nav__l1-link:active,
        .language-nav__l1-link:focus {
            color: $color-stone-grey;
        }

        .language-nav__l1-link:active,
        .language-nav__l1-link:focus,
        .language-nav__l1-link--state-active {
            font-weight: bold;
        }


    }
}
