.meat-selection {
    width: 100%;
    color: $color-white;
    display: flex;
    align-self: stretch;

    .container {
        display: flex;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @media #{$large-up} {
            flex-direction: row;
        }

        @media #{$xlarge-up} {
            margin-left: rem-calc(30px);
            margin-right: rem-calc(30px);
        }
    }

    &__intro {
        font-size: rem-calc(14px);
        margin-bottom: rem-calc(18px);
        line-height: 1.5;
        text-align: center;
    }

    &__left,
    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__left {
        width: 100%;
        flex-grow: 1;
        align-self: center;

        @media #{$medium-up} {
            width: 80%;
        }

        @media #{$large-up} {
            width: 100%;
            align-self: stretch;
        }
    }

    &__right {
        border-top: 1px solid rgba(#D8D8D8, 0.5);
        padding-top: rem-calc(23px);
        margin-top: rem-calc(26px);
        margin-bottom: rem-calc(26px);
        flex-shrink: 0;

        @media #{$large-up} {
            align-self: center;
            border-top: none;
            padding-top: 0;
            border-left: 1px solid rgba(#D8D8D8, 0.5);
            margin-left: rem-calc(20px);
            padding-left: rem-calc(20px);

            margin-left: rem-calc(40px);
            padding-left: rem-calc(40px);
        }

        @media #{$xlarge-up} {
            margin-left: rem-calc(83px);
            padding-left: rem-calc(83px);
        }
    }

    &__links {
        display: flex;
        counter-reset: meat-selection-links;
        margin-left: rem-calc(-23px);
    }

    &__linklist {
        text-transform: uppercase;
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: $font-family-montserrat;
        letter-spacing: rem-calc(1.64px);
        font-size: rem-calc(14px);
        font-weight: bold;
        line-height: 1.166;
    }

    &__linklist-item {
        counter-increment: meat-selection-links;
        margin-left: rem-calc(23px);

        &:not(:last-child) {
            border-bottom: 1px solid rgba(#D8D8D8, 0.5);
        }
    }

    &__linklist-link {
        display: flex;
        align-items: center;
        padding: rem-calc(13px) 0;
        padding-right: rem-calc(7px);
        color: $color-white;
        transition: color $animation-time-fast $animation-easing-default;
        backface-visibility: hidden;

        @media #{$large-up} {
            padding-right: rem-calc(20px);
        }

        &.hover,
        &:hover,
        &:focus,
        &:active {
            color: $color-green;
        }

        &::before {
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem-calc(20px);
            height: rem-calc(20px);
            line-height: rem-calc(20px);
            text-align: center;
            border-radius: 50%;
            content: counter(meat-selection-links);
            margin-right: rem-calc(6px);
            font-size: rem-calc(10px);
            color: $color-dark-stone-grey;
            background-color: $color-white;
            letter-spacing: 0;
            transition: all $animation-time-fast $animation-easing-default;
        }

        &.hover::before,
        &:hover::before,
        &:focus::before,
        &:active::before {
            background-color: $color-green;
            color: $color-white;
        }
    }

    &__svg-wrapper {
        width: 100%;
        position: relative;
        height: 0;

        @media #{$large-up} {
            height: 100%;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--rind &__svg-wrapper {
        padding-top: calc-ratio(560, 421);
    }

    &--schwein &__svg-wrapper {
        padding-top: calc-ratio(550, 292);
    }

    &--wild &__svg-wrapper {
        padding-top: calc-ratio(418, 478);
    }

    &--lamm &__svg-wrapper {
        padding-top: calc-ratio(397, 334);
    }

    &--kalb &__svg-wrapper {
        padding-top: calc-ratio(502, 361);
    }

    &--gefluegel &__svg-wrapper {
        padding-top: calc-ratio(521, 279);
    }

    &--gitzi &__svg-wrapper {
        padding-top: calc-ratio(297, 310);
    }

    &--gitzi &__svg-wrapper,
    &--rind &__svg-wrapper,
    &--schwein &__svg-wrapper,
    &--wild &__svg-wrapper,
    &--lamm &__svg-wrapper,
    &--kalb &__svg-wrapper,
    &--gefluegel &__svg-wrapper {
        @media #{$large-up} {
            padding-top: 0;
        }
    }

    .meat-svg__area-path {
        opacity: 0;
        transition: all $animation-time-fast $animation-easing-default;
    }

    .meat-svg__area {
        cursor: pointer;

        &.hover .meat-svg__area-path,
        &:hover .meat-svg__area-path {
            opacity: 1;
        }
    }
}
